import React from 'react';

import { Link } from 'src/components/Link';

export const LinkGroupCell = ({
  links,
  onClick,
}: {
  links: { text: string; href: string }[];
  onClick?: () => void;
}) => (
  <div className="link-group">
    {links.map((link) => (
      <Link key={link.text} to={link.href} onClick={onClick}>
        {link.text}
      </Link>
    ))}
  </div>
);
