import React, { useContext } from 'react';
import { AccountContext } from 'src/context/accountContext';

export const AccountHeader = () => {
  const { selectedAccount } = useContext(AccountContext);

  return (
    <div className="account-header">
      <div>
        <h1 className="account-header__name">{selectedAccount?.name}</h1>
        <div className="account-header__details">
          {selectedAccount?.id && (
            <span className="account-header__id">{selectedAccount.id}</span>
          )}
          {selectedAccount?.id && selectedAccount?.type && <span>|</span>}
          {selectedAccount?.type && (
            <span className="account-header__type">
              {selectedAccount.type.toLowerCase()}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
