import React, { useState } from 'react';
import { KatAlert, KatButton, KatModal } from '@amzn/katal-react';
import { DataTable } from './DataTable';
import { Row } from 'react-table';

interface FeatureSelectProps {
  featureList: string[];
  setSelectedFeatures: (selectedFeatures: string[]) => void;
  options?: {
    buttonVariant?: KatButton.Variant;
  };
}

export const FeatureSelect = ({
  featureList,
  setSelectedFeatures,
  options,
}: FeatureSelectProps) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [checkedRows, setCheckedRows] = useState<Row[]>([]);
  const [globalFilter, setGlobalFilter] = useState<string>('');

  return (
    <>
      <KatModal
        onClose={() => setModalVisible(false)}
        visible={modalVisible}
        title="Select Features"
        footer={
          <div className="modal-footer">
            <KatButton variant="link" onClick={() => setModalVisible(false)}>
              Cancel
            </KatButton>
            <KatButton
              variant="primary"
              onClick={() => {
                setSelectedFeatures(
                  checkedRows.reduce((prev, curr) => {
                    // @ts-expect-error
                    prev.push(curr['name']);
                    return prev;
                  }, []),
                );
                setModalVisible(false);
              }}
            >
              Submit
            </KatButton>
          </div>
        }
      >
        <div className="px-6 py-12">
          {checkedRows.length > 5 && (
            <KatAlert description="Selecting more than 5 features will cause the charts to not be rendered. However, the job table will render with any number of features." />
          )}
          <DataTable
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            selectedRows={checkedRows}
            setSelectedRows={setCheckedRows}
            columns={[
              {
                Header: 'Feature Name',
                id: 'feature-name',
                accessor: 'name',
                sortable: true,
              },
            ]}
            data={featureList.map((feature) => ({ name: feature }))}
          />
        </div>
      </KatModal>
      <KatButton
        variant={options?.buttonVariant ?? 'primary'}
        onClick={() => setModalVisible(true)}
      >
        Select Features
      </KatButton>
    </>
  );
};
