import React, { ReactNode, useState } from 'react';
import { useCollection } from '@cloudscape-design/collection-hooks';
import {
  Alert,
  Box,
  Button,
  CollectionPreferences,
  CollectionPreferencesProps,
  Header,
  Pagination,
  Table,
  TableProps,
  TextFilter,
} from '@cloudscape-design/components';

type ClientSideTableProps = Omit<TableProps, 'items'> & {
  data: unknown[];
  title: string;
  error: Error | null;
  actions?: ReactNode;
};

function EmptyState({
  title,
  action,
  error,
}: {
  title: string;
  action?: ReactNode;
  error: Error | null;
}) {
  return (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}
      </Box>
      {error && (
        <Alert
          statusIconAriaLabel="Error"
          type="error"
          header="Error loading data"
        >
          {error.message}
        </Alert>
      )}
      <Box padding="m">{action}</Box>
    </Box>
  );
}

export const ClientSideTable = (clientSideTableProps: ClientSideTableProps) => {
  const [preferences, setPreferences] =
    useState<CollectionPreferencesProps.Preferences>({
      pageSize: 10,
      contentDisplay: clientSideTableProps.columnDefinitions.map((col) => ({
        id: col.id || '',
        visible: true,
      })),
    });
  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps,
  } = useCollection(clientSideTableProps.data, {
    filtering: {
      empty: (
        <EmptyState title="No instances" error={clientSideTableProps?.error} />
      ),
      noMatch: (
        <EmptyState
          title="No matches"
          action={
            <Button onClick={() => actions.setFiltering('')}>
              Clear filter
            </Button>
          }
          error={null}
        />
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
  });

  const { selectedItems } = collectionProps;
  return (
    <Table
      {...collectionProps}
      {...clientSideTableProps}
      loading={clientSideTableProps.loading}
      header={
        <Header
          counter={
            selectedItems?.length
              ? `(${selectedItems?.length}/${clientSideTableProps.data.length})`
              : `(${clientSideTableProps.data.length})`
          }
          actions={clientSideTableProps.actions}
        >
          {clientSideTableProps.title}
        </Header>
      }
      columnDisplay={preferences.contentDisplay}
      items={items}
      pagination={
        <Pagination
          {...paginationProps}
          ariaLabels={{
            nextPageLabel: 'Next page',
            paginationLabel: 'Table pagination',
            previousPageLabel: 'Previous page',
            pageLabel: (pageNumber) => `Page ${pageNumber}`,
          }}
        />
      }
      filter={
        <TextFilter
          {...filterProps}
          countText={`${filteredItemsCount} matches`}
          filteringAriaLabel="Filter items"
          filteringText={filterProps.filteringText}
          filteringPlaceholder={'Find items'}
        />
      }
      preferences={
        <CollectionPreferences
          title="Preferences"
          cancelLabel="Cancel"
          confirmLabel="Confirm"
          pageSizePreference={{
            title: 'Page size',
            options: [
              { value: 10, label: '10 items' },
              { value: 20, label: '20 items' },
            ],
          }}
          preferences={preferences}
          onConfirm={({ detail }) => setPreferences(detail)}
        />
      }
    />
  );
};
