import React, { ReactNode } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'src/components/Link';

export const OperatorFlowLink = ({
  children,
  operatorFlowJobId,
}: {
  children?: ReactNode;
  operatorFlowJobId: string;
}) => {
  const { accountNo, applicationId, modelVersionId, modelArtifactId } =
    useParams<Record<string, string>>();

  return (
    <Link
      to={`/accounts/${accountNo}/applications/${applicationId}/modelVersions/${modelVersionId}/modelArtifacts/${modelArtifactId}/operatorFlowJobs/${operatorFlowJobId}/visualization`}
    >
      {children || operatorFlowJobId}
    </Link>
  );
};
