import React, { useContext } from 'react';
import { ContentLayout } from 'src/components/ContentLayout';
import { BreadcrumbNavigation } from 'src/components/BreadcrumbNavigation';
import { ApiContext } from 'src/context/apiContext';
import { ModelVersionDetails } from 'src/components/ModelVersionDetails';

export const ModelVersionPage = () => {
  const { state } = useContext(ApiContext);
  const currentApplicationId = state.search.curApplicationId;
  const applicationDetails = state.applications.data.find(
    (app) => app['id'] === currentApplicationId,
  );
  const currentModelVersionId = state.search.curModelVersionId;
  const modelVersionDetails = state.modelVersions.data.find(
    (mv) => mv['id'] === currentModelVersionId,
  );

  return (
    <ContentLayout
      heading={
        <>
          <BreadcrumbNavigation
            links={[
              {
                display: `Account (${state.search.curAccountNo})`,
                href: `/accounts/${state.search.curAccountNo}`,
              },
              {
                display: applicationDetails?.name,
                href: `/accounts/${state.search.curAccountNo}/applications/${state.search.curApplicationId}`,
              },
              {
                display: modelVersionDetails?.name,
              },
            ]}
          />
          <h1>{modelVersionDetails?.name}</h1>
        </>
      }
    >
      <ModelVersionDetails />
    </ContentLayout>
  );
};
