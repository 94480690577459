import { TrainingJob } from 'src/context/apiState';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from 'src/context/userContext';
import { apiGetRequest } from 'src/utils/mlPigeonAPIRequest';
import { convertDateToEpochTimeInSeconds } from 'src/utils/timeUtils';

export interface TrainingJobAPIResponse {
  id: string;
  modelArtifactId: string;
  trainingJobArn: string;
  trainingJobName: string;
  s3TrainingDatasetLocation: string;
  s3ArtifactsLocation: string;
  status: string;
  metrics?: string;
  operatorFlowJobId: string;
  operatorName: string;
  upstreamJobRelationships: { jobId: string; jobType: string }[];
  createdDate: string;
  createdBy: string;
  updatedDate: string;
  updatedBy: string;
}

export const useTrainingJobs = (
  modelArtifactId: string,
): [boolean, Error | null, TrainingJob[] | null] => {
  const user = useContext(UserContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [data, setData] = useState<TrainingJob[] | null>(null);

  useEffect(() => {
    if (!modelArtifactId) return;

    const fetchData = async () => {
      setLoading(true);
      try {
        const trainingJobs = await apiGetRequest({
          endpoint: `trainingJobs`,
          params: {
            modelArtifactId,
          },
        });

        const ids = trainingJobs.reduce((acc: string[], job: TrainingJob) => {
          acc.push(job.id);
          return acc;
        }, []);

        trainingJobs.sort((a: TrainingJob, b: TrainingJob) => {
          return (
            new Date(a.createdDate).getTime() -
            new Date(b.createdDate).getTime()
          );
        });

        const startDate = new Date(trainingJobs[0].createdDate * 1000);
        startDate.setHours(0, 0, 0, 0);
        const endDate = new Date(
          trainingJobs[trainingJobs.length - 1].createdDate * 1000,
        );
        endDate.setHours(23, 59, 59, 999);

        // get statuses
        const statuses = await apiGetRequest({
          endpoint: `operatorFlowJobs`,
          params: {
            enablePreviewMode: 'true',
            startTime: convertDateToEpochTimeInSeconds(startDate).toString(),
            endTime: convertDateToEpochTimeInSeconds(endDate).toString(),
            modelArtifactId,
          },
        });

        const jobList: TrainingJob[] = trainingJobs.map((job: any) => {
          ids.push(job.id);
          let parsedData;

          try {
            parsedData = JSON.parse(job.metrics);
          } catch {
            parsedData = [];
          }

          return {
            ...job,
            operatorFlowJobStatus:
              statuses.find(
                (status: OperatorFlowJob) =>
                  status?.id === job.operatorFlowJobId,
              )?.status || '',
            metrics: parsedData,
          };
        });

        setData(jobList);
      } catch (e) {
        setError(e as Error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [modelArtifactId, user.token]);

  return [loading, error, data];
};
