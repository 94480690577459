import React from 'react';
import { KatButton, KatModal } from '@amzn/katal-react';
import { ARN } from '@aws-sdk/util-arn-parser';
import { useManualDeployment } from 'src/hooks/useManualDeployment';

export const PromoteModal = ({
  promoteConfig,
  setPromoteConfig,
}: {
  promoteConfig: { modelName: string; arn: ARN } | null;
  setPromoteConfig: (
    arg: {
      modelName: string;
      arn: ARN;
    } | null,
  ) => void;
}) => {
  const { deploymentRequestLoading, requestDeployment } = useManualDeployment();
  return (
    <KatModal
      visible={promoteConfig !== null}
      onClose={() => {
        setPromoteConfig(null);
      }}
    >
      <span slot="title">Deploy model to Production?</span>
      <p>
        Using <strong>{promoteConfig?.modelName}</strong>, this will create a
        new model in the same account with the same settings, without the
        &apos;beta-&apos; prefix.
      </p>
      <div className="kat-group-horizontal" slot="footer">
        <KatButton
          label="Cancel"
          variant="link"
          onClick={() => {
            setPromoteConfig(null);
          }}
        />
        <KatButton
          label="Request Promotion"
          loading={deploymentRequestLoading}
          onClick={() => {
            promoteConfig && requestDeployment(promoteConfig.arn);
            setPromoteConfig(null);
          }}
        />
      </div>
    </KatModal>
  );
};
