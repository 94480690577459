import React, { useState } from 'react';
import { ModelVersionList } from 'src/components/ModelVersionList';
import { ProfileDetails } from 'src/components/ProfileDetails';
import { KatDropdownButton } from '@amzn/katal-react';
import { useNavigate, useParams } from 'react-router-dom';
import { getApplication } from 'src/hooks/useApplications';
import { ApplicationModal } from 'src/components/ApplicationModal';
import { Placeholder } from 'src/components/Placeholder';
import { getRoleName } from 'src/utils/generalUtils';

export function ApplicationDetails() {
  const { accountNo, applicationId } = useParams();
  const { data, isLoading, error } = getApplication({
    account: accountNo,
    id: applicationId,
  });
  const [applicationModalVisible, setApplicationModalVisible] =
    useState<boolean>(false);
  const [applicationModalMode, setApplicationModalMode] = useState<
    'New' | 'Edit' | 'Delete'
  >('New');
  const [applicationModalValues, setApplicationModalValues] =
    useState<any>(null);

  const showApplicationModal = (
    mode: 'New' | 'Edit' | 'Delete',
    values: any,
  ) => {
    setApplicationModalValues(values);
    setApplicationModalMode(mode);
    setApplicationModalVisible(true);
  };

  const navigate = useNavigate();

  if (error && error.message.includes('does not exist')) {
    navigate('/404');
  }

  const actionHandler = (event: KatDropdownButton.ActionEvent) => {
    switch (event.detail.action) {
      case 'edit_application':
        if (!data) return;
        showApplicationModal('Edit', {
          account: accountNo,
          id: applicationId,
          name: data.name,
          description: data.description,
          s3ScriptFileLocation: data.s3ScriptFileLocation,
          roleArn: data.roleArn,
          roleExternalId: data.roleExternalId,
          version: data.version,
        });
        break;
      case 'delete_application':
        showApplicationModal('Delete', {
          account: accountNo,
          id: applicationId,
        });
        break;
    }
  };

  return (
    <div className="application-details">
      <div className="application-details__header">
        <h2>Application Details</h2>
        <div className="application-details__button-group">
          <KatDropdownButton
            size="base"
            variant="secondary"
            options='[{"label": "Edit application", "action": "edit_application"}, {"label": "Delete application", "action": "delete_application"}]'
            onAction={actionHandler}
          />
        </div>
      </div>
      <div className="details-display">
        <Placeholder
          shapes={
            <>
              <rect y="0" width="300" height="282" />
            </>
          }
          ready={!isLoading}
        >
          <dl>
            <dt>Name</dt>
            <dd>{data?.name}</dd>

            <dt>Id</dt>
            <dd>{data?.id}</dd>

            <dt>Access IAM Role Name</dt>
            <dd>{data?.roleArn ? getRoleName(data.roleArn) : ''}</dd>

            <dt>Access IAM Role External Id</dt>
            <dd>{data?.roleExternalId}</dd>

            <dt>User Script S3 Location</dt>
            <dd>{data?.s3ScriptFileLocation}</dd>

            <dt>Description</dt>
            <dd>{data?.description}</dd>

            <dt>Version</dt>
            <dd>{data?.version || 1}</dd>
          </dl>
        </Placeholder>
      </div>

      <ModelVersionList />

      <div className="profile-details">
        <Placeholder
          shapes={
            <>
              <rect y="0" width="90" height="25" />
              <rect x="30%" y="0" width="90" height="25" />
              <rect x="60%" y="0" width="90" height="25" />
              <rect x="80%" y="0" width="90" height="25" />
            </>
          }
          ready={!isLoading}
        >
          <ProfileDetails
            createdBy={data?.createdBy || ''}
            updatedBy={data?.updatedBy || ''}
            createdDate={data?.createdDate || ''}
            updatedDate={data?.updatedDate || ''}
          />
        </Placeholder>
      </div>
      {accountNo && (
        <ApplicationModal
          visible={applicationModalVisible}
          modalMode={applicationModalMode}
          values={applicationModalValues}
          onClose={() => {
            setApplicationModalVisible(false);
          }}
          account={accountNo}
          redirectLocation={
            applicationModalMode === 'Delete'
              ? `/accounts/${accountNo}`
              : undefined
          }
        />
      )}
    </div>
  );
}
