import React from 'react';
import { KatIcon } from '@amzn/katal-react';
import { DateCell } from 'src/components/DateCell';

export const DateColumn = ({
  header,
  id,
  accessor,
  showRelativeDate,
  setShowRelativeDate,
  width,
}: {
  header: string;
  id?: string;
  accessor: string;
  showRelativeDate: boolean;
  setShowRelativeDate: (arg: boolean) => void;
  width?: number;
}) => ({
  Header: header,
  id,
  accessor,
  accessorString: accessor,
  width: width || 175,
  sortable: true,
  dateControl: () => (
    <KatIcon
      title="toggle relative date"
      className="application-list__toggle-date-icon"
      name="clock"
      size="small"
      onClick={() => {
        setShowRelativeDate(!showRelativeDate);
      }}
    />
  ),
  showRelativeDate,
  Cell: DateCell,
});
