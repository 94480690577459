import React, { useContext, useEffect, useState } from 'react';
import {
  KatBox,
  KatButton,
  KatDropdown,
  KatInput,
  KatList,
  KatModal,
} from '@amzn/katal-react';
import { Link } from 'src/components/Link';
import {
  ModelVersionEventType,
  supportedEventTypes,
} from 'src/context/apiState';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { ApiContext } from 'src/context/apiContext';
import { useQueryClient } from '@tanstack/react-query';
import {
  createModelVersionEvent,
  deleteModelVersionEvent,
  editModelVersionEvent,
} from 'src/hooks/useModelVersionEvents';

export const ModelVersionEventFormModal = ({
  modalMode,
  values,
  onClose,
}: {
  modalMode: 'New' | 'Edit' | 'Delete';
  values?: {
    type: string;
    s3Bucket: string;
    s3Prefix: string;
    operatorName?: string;
    id?: string;
  };
  onClose: () => void;
}) => {
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState<boolean>(false);
  const modelVersionEventValidationSchema = yup.object().shape({
    type: yup
      .string()
      .oneOf(
        supportedEventTypes.map((item) => item.value),
        'Please select an event type.',
      )
      .required('Please select an event type.'),

    s3Bucket: yup
      .string()
      .matches(
        /^[\w.-]+$/g,
        'The bucket name may only consist of letters, digits or the following special characters: .-',
      )
      .min(3, 'Bucket names must be at least 3 characters long.')
      .max(63, 'Bucket names must be at most, 63 characters long.')
      .required('Please supply an s3 bucket name.'),

    s3Prefix: yup
      .string()
      .matches(
        /^[\w.,/_=${}\-[\]]+$/g,
        'The prefix may only consist of letters, digits or the following special characters: .,/_-=${}[]',
      )
      .required('Please supply an s3 bucket prefix.'),

    operatorName: yup
      .string()
      .matches(
        /^[\w.-]+$/g,
        'The operator name may only consist of letters, digits or the following special characters: ._-',
      ),
  });

  const initialValues = {
    type: values?.type || '',
    s3Bucket: values?.s3Bucket || '',
    s3Prefix: values?.s3Prefix || '',
    operatorName: values?.operatorName || '',
    id: values?.id || '',
  };

  const { state } = useContext(ApiContext);
  const [error, setError] = useState<Error | null>(null);
  const [detectedVariables, setDetectedVariables] = useState<string[]>([]);
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  useEffect(() => {
    setModalVisible(true);
  }, []);

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      let response;
      // check the mode, call the correct api
      setLoading(true);
      switch (modalMode) {
        case 'New':
          response = await createModelVersionEvent(
            {
              modelVersionEvent: {
                id: values.id,
                type: values.type as ModelVersionEventType,
                s3Bucket: values.s3Bucket,
                s3Prefix: values.s3Prefix,
                operatorName: values.operatorName,
              },
              modelVersionId: state.search.curModelVersionId,
            },
            queryClient,
          )
            .catch((e) => {
              setError(e);
              return e.message;
            })
            .finally(() => {
              setLoading(false);
            });
          break;
        case 'Edit':
          response = await editModelVersionEvent(
            {
              modelVersionEvent: {
                id: values.id,
                type: values.type as ModelVersionEventType,
                s3Bucket: values.s3Bucket,
                s3Prefix: values.s3Prefix,
                operatorName: values.operatorName,
              },
              modelVersionId: state.search.curModelVersionId,
            },
            queryClient,
          )
            .catch((e) => {
              setError(e);
              return e.message;
            })
            .finally(() => {
              setLoading(false);
            });
          break;
      }

      if (!response) onClose();
    },
    validationSchema: modelVersionEventValidationSchema,
  });

  useEffect(() => {
    if (!formik.values.s3Prefix) return;
    const varRegex = /\${\w+}/gm;
    const matches = formik.values.s3Prefix.match(varRegex);
    setDetectedVariables(
      matches ? matches.map((match) => match.replace(/[${}]/gm, '')) : [],
    );
  }, [formik.values.s3Prefix]);

  const handleDeleteConfirm = async () => {
    if (!values?.id) return;
    setLoading(true);
    const response = await deleteModelVersionEvent(
      {
        modelVersionEventId: values.id,
        modelVersionId: state.search.curModelVersionId,
      },
      queryClient,
    )
      .catch((e) => {
        setError(e);
        return e.message;
      })
      .finally(() => {
        setLoading(false);
      });

    if (!response) onClose();
  };

  return (
    <KatModal
      visible={modalVisible}
      onClose={() => {
        onClose();
      }}
      title={`${modalMode} Model Version Event`}
      footer={
        <div className="modal-footer">
          {error && <p className="error-text">{error.message}</p>}
          <KatButton variant="link" onClick={onClose}>
            Cancel
          </KatButton>
          <KatButton
            loading={loading}
            variant={modalMode === 'Delete' ? 'danger' : 'primary'}
            onClick={() => {
              modalMode === 'Delete'
                ? handleDeleteConfirm()
                : formik.handleSubmit();
            }}
          >
            {modalMode === 'Delete' ? 'Delete Event' : 'Submit'}
          </KatButton>
        </div>
      }
    >
      {modalMode === 'Delete' ? (
        <p>This will delete the Model Version Event. Confirm?</p>
      ) : (
        <div className="modal-form">
          <div>
            <p>
              A Model Version Event will listen for S3 events, matching the
              bucket and prefix given, and will trigger an operator flow.
            </p>
            <form onSubmit={formik.handleSubmit}>
              <KatDropdown
                name="type"
                label="Event type"
                value={values?.type || formik.values.type}
                options={supportedEventTypes}
                placeholder="Select an event type"
                onChange={formik.handleChange}
                state={formik.errors.type ? 'error' : undefined}
                stateLabel={formik.errors.type}
              />
              <KatBox variant="ltgrey">
                <p>
                  Provide the S3 bucket and prefix that MLPigeon should match on
                  in order to trigger workflows for this event.
                </p>
                <KatInput
                  label="S3 Bucket Name"
                  value={values?.s3Bucket || formik.values.s3Bucket}
                  placeholder="my-bucket-name"
                  name="s3Bucket"
                  onChange={formik.handleChange}
                  onBlur={() => {
                    formik.setTouched({ ...formik.touched, s3Bucket: true });
                  }}
                  state={
                    formik.touched.s3Bucket && formik.errors.s3Bucket
                      ? 'error'
                      : undefined
                  }
                  stateLabel={formik.errors.s3Bucket}
                />
                <KatInput
                  label="S3 Bucket Prefix"
                  value={values?.s3Prefix || formik.values.s3Prefix}
                  placeholder="input/data"
                  name="s3Prefix"
                  onChange={formik.handleChange}
                  onBlur={() => {
                    formik.setTouched({ ...formik.touched, s3Prefix: true });
                  }}
                  state={
                    formik.touched.s3Prefix && formik.errors.s3Prefix
                      ? 'error'
                      : undefined
                  }
                  stateLabel={formik.errors.s3Prefix}
                />
                <p>
                  S3 Bucket Prefix supports using variables. See our{' '}
                  <Link
                    to="https://w.amazon.com/bin/view/MLPigeon/FAQ#HHowtousevariablesinuserscriptandinS3Prefix28ModelVersionEvent293F"
                    external
                  >
                    wiki
                  </Link>{' '}
                  for more information.
                </p>
                <div>
                  <p>Detected Variables</p>
                  {detectedVariables.length === 0 ? (
                    'No variables detected'
                  ) : (
                    <KatList variant="bullet">
                      {detectedVariables.map((variable: string) => (
                        <li key={variable}>{variable}</li>
                      ))}
                    </KatList>
                  )}
                </div>
              </KatBox>
              <KatInput
                label="Operator Name"
                value={values?.operatorName || formik.values.operatorName}
                name="operatorName"
                placeholder="my-operator-name"
                constraintEmphasis="Note"
                constraintLabel="If you only have one event of the selected type, you may omit the
            operator name."
                onChange={formik.handleChange}
                onBlur={() => {
                  formik.setTouched({ ...formik.touched, operatorName: true });
                }}
                state={
                  formik.touched.operatorName && formik.errors.operatorName
                    ? 'error'
                    : undefined
                }
                stateLabel={formik.errors.operatorName}
              />
            </form>
          </div>
        </div>
      )}
    </KatModal>
  );
};
