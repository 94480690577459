import React, { ReactElement, Suspense, useContext, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { ToastPortal } from 'src/components/ToastPortal';
import { KatSpinner } from '@amzn/katal-react';
import { Routing } from 'src/components/Routing';
import {
  AppLayout,
  SideNavigation,
  SideNavigationProps,
} from '@cloudscape-design/components';
import SiteHeader from 'src/components/SiteHeader';
import { AccountContext } from 'src/context/accountContext';

function SiteContainer(): ReactElement {
  const [isNavigationOpen, setIsNavigationOpen] = useState(true);
  const { selectedAccount } = useContext(AccountContext);
  const [activeHref, setActiveHref] = useState(window.location.pathname);
  const navigate = useNavigate();

  const accountRequiredItems: SideNavigationProps.Item[] = [
    {
      type: 'section',
      text: 'MLPigeon',
      items: [
        {
          type: 'link',
          text: 'Applications',
          href: `/accounts/${selectedAccount?.id}`,
        },
        {
          type: 'link',
          text: 'Topic Subscriptions',
          href: `/accounts/${selectedAccount?.id}/topics`,
        },
        {
          type: 'link',
          text: 'Account Metrics',
          href: `/accounts/${selectedAccount?.id}/accountMetrics`,
        },
      ],
    },
  ];

  const secondaryNavSubItems: SideNavigationProps.Item[] = [
    {
      type: 'link',
      text: 'Browse Models',
      href: '/modelRegistry',
    },
  ];
  if (selectedAccount) {
    secondaryNavSubItems.push({
      type: 'link',
      text: 'Reserve a Registry Name',
      href: `/accounts/${selectedAccount?.id}/reserveRegistryName`,
    });
  }

  const generalItems: SideNavigationProps.Item[] = [
    {
      type: 'section',
      text: 'Model Registry',
      items: secondaryNavSubItems,
    },
    { type: 'divider' },
    {
      type: 'link',
      text: 'MLPigeon Wiki',
      href: 'https://w.amazon.com/bin/view/MLPigeon',
      external: true,
    },
    {
      type: 'link',
      text: 'Python Package Search',
      href: 'https://prod.peruse.builder-tools.aws.dev/search/pypi',
      external: true,
    },
  ];

  const secondaryNavItems = [];
  if (selectedAccount) {
    secondaryNavItems.push(...accountRequiredItems);
  }
  secondaryNavItems.push(...generalItems);

  return (
    <>
      <Routing />
      <SiteHeader />
      <AppLayout
        ariaLabels={{
          navigationClose: 'close navigation',
          navigationToggle: 'toggle navigation',
        }}
        disableContentPaddings
        headerSelector={'#h'}
        navigationOpen={isNavigationOpen}
        toolsHide
        onNavigationChange={(event) => {
          setIsNavigationOpen(event.detail.open);
        }}
        navigation={
          <SideNavigation
            activeHref={activeHref}
            onFollow={(e) => {
              if (!e.detail.external && e.detail.href !== '#nogo') {
                e.preventDefault();
                setActiveHref(e.detail.href);
                navigate(e.detail.href);
              }
            }}
            items={secondaryNavItems}
          />
        }
        content={
          <div className="katal">
            <main>
              <Suspense
                fallback={
                  <div className="chunk-loading-spinner">
                    <KatSpinner size="large" />
                  </div>
                }
              >
                <Outlet />
              </Suspense>
              <ToastPortal autoClose autoCloseTime={7000} />
            </main>
          </div>
        }
      />
    </>
  );
}

export default SiteContainer;
