import React from 'react';

import { Link } from 'src/components/Link';

export const MonitoringScheduleIdColumn = (
  accountNo: string,
  applicationId: string,
  modelVersionId: string,
  modelArtifactId: string,
) => {
  const MonitoringScheduleIdColumnCell = (cellInfo: any) => (
    <Link
      to={`/accounts/${accountNo}/applications/${applicationId}/modelVersions/${modelVersionId}/modelArtifacts/${modelArtifactId}/monitoringSchedules/${cellInfo.value}/dataQualityMetrics`}
    >
      {cellInfo.value}
    </Link>
  );
  return {
    Header: 'Id',
    accessor: 'id',
    sortable: true,
    width: 300,
    Cell: MonitoringScheduleIdColumnCell,
  };
};
