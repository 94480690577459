import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import SiteContainer from 'src/components/SiteContainer';
import {
  LazyAccountSettingsPage,
  LazyCLIOnboardingPage,
  LazyDashboardMetricsPage,
  LazyManualDeploymentPage,
  LazyNewTopicSubscriptionPage,
  LazyOnboardingPage,
  LazyOperatorHistoryPage,
  LazyTopicSubscriptionPage,
  LazyUserScriptGenerator,
  LazyModelRegistryHomePage,
  LazyModelRegistryModelPage,
  LazyModelVariationPage,
  LazyReserveRegistryNamePage,
  LazyEditModelDescriptionPage,
  LazyEditReleaseNotesPage,
  LazyLogViewPage,
  LazyUnauthorizedPage,
} from 'src/lazyRoutes';
import { Home } from 'src/views/Home';
import { AccountPage } from 'src/views/AccountPage';
import { FileNotFoundPage } from 'src/views/FileNotFoundPage';
import { ApplicationPage } from 'src/views/ApplicationPage';
import { ModelVersionPage } from 'src/views/ModelVersionPage';
import { DataQualityMetricsPage } from 'src/views/DataQualityMetricsPage';
import { ModelMetricsPage } from 'src/views/ModelMetricsPage';
import { OperatorFlowsPage } from 'src/views/OperatorFlowsPage';
import { OperatorFlowPage } from 'src/views/OperatorFlowPage';
import { PrivateRoute } from './components/PrivateRoute';
import { AccountProvider } from './context/accountContext';

export const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <AccountProvider>
        <SiteContainer />
      </AccountProvider>
    ),
    children: [
      {
        path: '',
        element: <Home />,
      },
      {
        path: 'accounts/:accountNo',
        element: (
          <PrivateRoute>
            <AccountPage />
          </PrivateRoute>
        ),
      },
      {
        path: 'accounts/:accountNo/accountSettings',
        element: (
          <PrivateRoute>
            <LazyAccountSettingsPage />
          </PrivateRoute>
        ),
      },
      {
        path: 'accounts/:accountNo/topics',
        element: (
          <PrivateRoute>
            <LazyTopicSubscriptionPage />
          </PrivateRoute>
        ),
      },
      {
        path: 'accounts/:accountNo/topics/new',
        element: (
          <PrivateRoute>
            <LazyNewTopicSubscriptionPage />
          </PrivateRoute>
        ),
      },
      {
        path: 'accounts/:accountNo/accountMetrics',
        element: (
          <PrivateRoute>
            <LazyDashboardMetricsPage isAccountMetrics />
          </PrivateRoute>
        ),
      },
      {
        path: 'accounts/:accountNo/applications/:applicationId',
        element: (
          <PrivateRoute>
            <ApplicationPage />
          </PrivateRoute>
        ),
      },
      {
        path: 'accounts/:accountNo/reserveRegistryName',
        element: (
          <PrivateRoute>
            <LazyReserveRegistryNamePage />
          </PrivateRoute>
        ),
      },
      {
        path: 'accounts/:accountNo/applications/:applicationId/modelVersions/:modelVersionId',
        element: (
          <PrivateRoute>
            <ModelVersionPage />
          </PrivateRoute>
        ),
      },
      {
        path: 'accounts/:accountNo/applications/:applicationId/modelVersions/:modelVersionId/modelArtifacts/:modelArtifactId/dataQualityMetrics',
        element: (
          <PrivateRoute>
            <DataQualityMetricsPage />
          </PrivateRoute>
        ),
      },
      {
        path: 'accounts/:accountNo/applications/:applicationId/modelVersions/:modelVersionId/modelArtifacts/:modelArtifactId/trainingJobs/:trainingJobId/dataQualityMetrics',
        element: (
          <PrivateRoute>
            <DataQualityMetricsPage />
          </PrivateRoute>
        ),
      },
      {
        path: 'accounts/:accountNo/applications/:applicationId/modelVersions/:modelVersionId/modelArtifacts/:modelArtifactId/monitoringSchedules/:monitoringScheduleId/dataQualityMetrics',
        element: (
          <PrivateRoute>
            <DataQualityMetricsPage />
          </PrivateRoute>
        ),
      },
      {
        path: 'accounts/:accountNo/applications/:applicationId/modelVersions/:modelVersionId/modelArtifacts/:modelArtifactId/monitoring',
        element: (
          <PrivateRoute>
            <ModelMetricsPage />
          </PrivateRoute>
        ),
      },
      {
        path: 'accounts/:accountNo/applications/:applicationId/modelVersions/:modelVersionId/modelArtifacts/:modelArtifactId/operatorFlowJobs',
        element: (
          <PrivateRoute>
            <OperatorFlowsPage />
          </PrivateRoute>
        ),
      },
      {
        path: 'accounts/:accountNo/applications/:applicationId/modelVersions/:modelVersionId/modelArtifacts/:modelArtifactId/operatorFlowJobs/:operatorFlowId/visualization',
        element: (
          <PrivateRoute>
            <OperatorFlowPage />
          </PrivateRoute>
        ),
      },
      {
        path: 'accounts/:accountNo/applications/:applicationId/modelVersions/:modelVersionId/modelArtifacts/:modelArtifactId/operatorFlowJobs/:operatorFlowId/logs',
        element: (
          <PrivateRoute>
            <LazyLogViewPage />
          </PrivateRoute>
        ),
      },
      {
        path: 'accounts/:accountNo/applications/:applicationId/modelVersions/:modelVersionId/modelArtifacts/:modelArtifactId/operatorFlowJobs/:operatorFlowId/visualization/operatorHistory',
        element: (
          <PrivateRoute>
            <LazyOperatorHistoryPage />
          </PrivateRoute>
        ),
      },
      {
        path: 'accountSetup',
        element: <LazyOnboardingPage />,
      },
      {
        path: 'userscript',
        element: <LazyUserScriptGenerator />,
      },
      {
        path: 'clionboardingtoken',
        element: <LazyCLIOnboardingPage />,
      },
      {
        path: 'manualdeployment',
        element: (
          <PrivateRoute>
            <LazyManualDeploymentPage />
          </PrivateRoute>
        ),
      },
      {
        path: 'platformMetrics',
        element: (
          <PrivateRoute>
            <LazyDashboardMetricsPage isAccountMetrics={false} />
          </PrivateRoute>
        ),
      },
      {
        path: 'modelRegistry',
        element: <LazyModelRegistryHomePage />,
      },
      {
        path: 'modelRegistry/model/:modelRegistryId',
        element: <LazyModelRegistryModelPage />,
      },
      {
        path: 'modelRegistry/model/:modelRegistryId/variation/:variationId',
        element: <LazyModelVariationPage />,
      },
      {
        path: 'modelRegistry/model/:modelRegistryId/variation/:variationId/edit',
        element: <LazyEditModelDescriptionPage />,
      },
      {
        path: 'modelRegistry/model/:modelRegistryId/variation/:variationId/version/:variationVersionId/edit-release-notes',
        element: <LazyEditReleaseNotesPage />,
      },
      {
        path: 'unauthorized',
        element: <LazyUnauthorizedPage />,
      },
      {
        path: '*',
        element: <FileNotFoundPage />,
      },
    ],
  },
]);
