import React, { useEffect, useState, ReactElement, useContext } from 'react';
import { createPortal } from 'react-dom';
import { ToastMessage } from 'src/components/ToastMessage';
import { ApiContext } from 'src/context/apiContext';
import {
  HOLD_TOAST,
  TIMEOUT_REMOVAL_CHECK_TOAST,
  REMOVE_TOAST,
} from 'src/context/reducer';
import { IToastMessage } from 'src/context/apiState';

export const ToastPortal = ({
  autoClose = true,
  autoCloseTime = 5000,
}: {
  autoClose?: boolean;
  autoCloseTime?: number;
}): ReactElement => {
  const { state, dispatch } = useContext(ApiContext);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [removing, setRemoving] = useState<string>('');
  const mountingElement = document.createElement('div');
  mountingElement.id = 'toast-portal-mount-point';

  useEffect(() => {
    document.body.prepend(mountingElement);
    setLoaded(true);

    return () => {
      mountingElement.parentElement?.removeChild(mountingElement);
    };
  }, []);

  useEffect(() => {
    // will fire on dismissed toasts, but the check to prevent it would be the same work as the removal
    if (removing) {
      const removalToast = state.toasts.find((toast) => toast.id === removing);
      removalToast?.onTimeout?.();
      dispatch({
        type: TIMEOUT_REMOVAL_CHECK_TOAST,
        payload: { id: removing },
      });
    }
  }, [removing]);

  useEffect(() => {
    if (!state.toasts.length) return;
    state.toasts.map((toast) => {
      if (autoClose) {
        setTimeout(() => {
          setRemoving(toast.id);
        }, toast.dismissTime || autoCloseTime);
      }
    });
  }, [state.toasts]);

  if (loaded) {
    const injectionSite = document.getElementById('toast-portal-mount-point');
    return injectionSite ? (
      createPortal(
        <div className="katal toast-portal">
          {state.toasts.map((item: IToastMessage) => (
            <ToastMessage
              key={item.id}
              dismissTime={item.dismissTime || (autoClose ? autoCloseTime : 0)}
              onClick={(event) => {
                dispatch({
                  type: HOLD_TOAST,
                  payload: { id: event.target.id },
                });
              }}
              held={item.held}
              onDismiss={() => {
                dispatch({
                  type: REMOVE_TOAST,
                  payload: { id: item.id },
                });
              }}
              {...item}
            />
          ))}
        </div>,
        injectionSite,
      )
    ) : (
      <></>
    );
  }

  return <></>;
};
