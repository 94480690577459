import React, {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Account } from 'src/hooks/useGetAccounts';
import { useLocalStorage } from '@uidotdev/usehooks';
import { useNavigate, useParams } from 'react-router-dom';
import { apiGetRequest } from 'src/utils/mlPigeonAPIRequest';
import { ApiContext } from 'src/context/apiContext';

export const AccountContext = createContext<{
  loading: boolean;
  error: Error | null;
  setSelectedAccount: (account: Account | null) => void;
  selectedAccount: Account | null;
  recentAccounts: Account[];
  signin: (account: Account, path?: string) => void;
  signout: () => void;
}>({
  loading: false,
  error: null,
  selectedAccount: null,
  setSelectedAccount: () => null,
  recentAccounts: [],
  signin: () => null,
  signout: () => null,
});

export const AccountProvider = (props: { children: ReactElement }) => {
  const { dispatch } = useContext(ApiContext);
  const { accountNo } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [localStorageAccount, setLocalStorageAccount] =
    useLocalStorage<Account | null>('selectedAccount', null);
  const [error, setError] = useState<Error | null>(null);
  const [selectedAccount, setSelectedAccount] = useState<Account | null>(null);

  const [recentAccounts, setRecentAccounts] = useLocalStorage<Account[]>(
    'recentAccounts',
    [],
  );

  useEffect(() => {
    // if there is no account in local storage, or if the account in the url does not match what's in storage,
    // fetch the details for the url account and load that, then update storage
    if (
      (!localStorageAccount && accountNo) ||
      (localStorageAccount &&
        accountNo &&
        localStorageAccount?.id !== accountNo)
    ) {
      setLoading(true);
      apiGetRequest({ endpoint: `accounts` })
        .then((accounts) => {
          const account = accounts.find(
            (account: Account) => account.id === accountNo,
          );
          if (account) {
            setSelectedAccount({
              id: account.id,
              name: account.name,
              type: account.type,
            });
            setLocalStorageAccount({
              id: account.id,
              name: account.name,
              type: account.type,
            });
            dispatch({
              type: 'setCurrentAccountNo',
              payload: account.id,
            });
          } else {
            throw new Error('account not found');
          }
        })
        .catch((e) => {
          console.error(e);
          setError(e);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setSelectedAccount(localStorageAccount);
      dispatch({
        type: 'setCurrentAccountNo',
        payload: localStorageAccount?.id,
      });
      setLoading(false);
    }
  }, [accountNo]);

  const signin = (account: Account, path?: string) => {
    setLocalStorageAccount(account);
    setSelectedAccount(account);

    // for api context
    dispatch({ type: 'setCurrentAccountNo', payload: account.id });

    // update recently used accounts in local storage
    //is the incoming account in the list? remove it
    const index = recentAccounts?.findIndex(
      (storedAccount) => storedAccount.id === account.id,
    );

    const localRecentAccounts = recentAccounts ?? [];

    if (index > -1) {
      localRecentAccounts.splice(index, 1);
    }

    localRecentAccounts.unshift(account);

    while (localRecentAccounts.length > 3) {
      localRecentAccounts.pop();
    }

    setRecentAccounts(localRecentAccounts);

    navigate(path ?? `/accounts/${account.id}`);
  };

  const signout = () => {
    setSelectedAccount(null);
    setLocalStorageAccount(null);
    navigate('/');
  };

  return (
    <AccountContext.Provider
      value={{
        loading,
        selectedAccount,
        setSelectedAccount,
        recentAccounts,
        error,
        signin,
        signout,
      }}
    >
      {props.children}
    </AccountContext.Provider>
  );
};
