import React from 'react';
import { initialMetricsPublisher } from 'src/utils/metrics';
import { HeroSection } from 'src/components/HeroSection';
import { PointsSection } from 'src/components/PointsSection';
import { WorkflowSection } from 'src/components/WorkflowSection';
import { DevelopmentSection } from 'src/components/DevelopmentSection';
import { CommunitySection } from 'src/components/CommunitySection';
import { Link } from 'src/components/Link';

export function Home() {
  const actionMetricsPublisher =
    initialMetricsPublisher.newChildActionPublisherForMethod('HomePage');

  return (
    <div>
      <HeroSection actionMetricsPublisher={actionMetricsPublisher} />
      <PointsSection />
      <WorkflowSection />
      <DevelopmentSection />
      <CommunitySection actionMetricsPublisher={actionMetricsPublisher} />

      <footer className="homepage-footer">
        <ul>
          <li>
            <Link
              to="https://w.amazon.com/index.php/MLPigeon/FeaturesProvidedDetail"
              external
            >
              See our Full Feature List
            </Link>
          </li>
          <li>
            <Link to="https://w.amazon.com/bin/view/MLPigeon" external>
              Visit Our Wiki
            </Link>
          </li>
          <li>
            <Link to="https://w.amazon.com/bin/view/MLPigeon/FAQ">FAQs</Link>
          </li>
        </ul>
      </footer>
    </div>
  );
}
