import React, { useContext, useState } from 'react';
import { ADD_TOAST } from 'src/context/reducer';
import { ApiContext } from 'src/context/apiContext';
import { apiPostRequest } from 'src/utils/mlPigeonAPIRequest';
export interface ApprovalConfig {
  flowId: string;
  operatorName: string;
  statusMessage: string;
  decision: string;
}

export const useApprovalDecision = (): [
  boolean,
  (
    { flowId, operatorName, statusMessage, decision }: ApprovalConfig,
    successCallback?: () => void,
  ) => void,
] => {
  const [loading, setLoading] = useState<boolean>(false);
  const { dispatch } = useContext(ApiContext);

  const sendApprovalDecision = (
    { flowId, operatorName, statusMessage, decision }: ApprovalConfig,
    successCallback?: () => void,
  ) => {
    setLoading(true);
    const approvalTriggerDetails = {
      operatorFlowJobId: flowId,
      operatorName,
      statusMessage,
      newStatus: decision,
    };
    apiPostRequest({
      endpoint: 'approvalOperatorStatus',
      data: approvalTriggerDetails,
    })
      .then(() => {
        successCallback?.();
        dispatch({
          type: ADD_TOAST,
          payload: {
            header: 'Request Submitted Successfully',
            variant: 'success',
            description: (
              <div>
                <p>
                  {approvalTriggerDetails.newStatus === 'SUCCEEDED'
                    ? 'Successfully Approved'
                    : 'Successfully Rejected'}
                </p>
              </div>
            ),
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: ADD_TOAST,
          payload: {
            header: 'Error Sending Request',
            variant: 'danger',
            description: (
              <div>
                <p>There was an issue submitting your request. {err.message}</p>
              </div>
            ),
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return [loading, sendApprovalDecision];
};
