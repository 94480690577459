/* istanbul ignore file */
import React, { createContext, useReducer } from 'react';
import { initialState } from './initialState';
import { reducer } from './reducer';
import Auth from '../auth/cognito';

const auth = new Auth();

export interface UserState {
  name?: string;
  token?: any;
}

export const UserContext = createContext<UserState>(initialState.user);

export const UserContextProvider = (props: any) => {
  const [state, dispatch] = useReducer(reducer, initialState.user);
  if (!state.isAuthenticated) {
    //verifiedDomains.includes(window.location.origin) ?
    auth.handleAuthentication(() => {
      const authenticatedUserEvent = new CustomEvent('cathode-customerId', {
        detail: {
          customerId: auth.getUserId(),
        },
      });
      document.dispatchEvent(authenticatedUserEvent);

      dispatch({
        type: 'loginUser',
        payload: {
          authenticated: true,
          user: auth.getUserId(),
          token: auth.getJwtToken(),
        },
      });
    });
    //: alert("User origin not recognised by Cognito: login attempt failed");
    return <></>;
  }

  return (
    <UserContext.Provider
      value={{
        ...state,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};
