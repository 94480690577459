import { MonitoringSchedule } from 'src/context/apiState';
import { useEffect, useState } from 'react';
import { apiGetRequest } from 'src/utils/mlPigeonAPIRequest';

export const useMonitoringSchedules = (
  modelArtifactId: string,
): [boolean, Error | null, MonitoringSchedule[] | null] => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [data, setData] = useState<MonitoringSchedule[] | null>(null);
  useEffect(() => {
    if (!modelArtifactId) return;
    setLoading(true);

    apiGetRequest({
      endpoint: `monitoringSchedules`,
      params: {
        modelArtifactId,
      },
    })
      .then((data) => {
        const succeededMonitoringSchedules: MonitoringSchedule[] = [];
        data.map((item: any) => {
          if (item.status === 'SUCCEEDED')
            succeededMonitoringSchedules.push(item);
        });
        setData(succeededMonitoringSchedules);
      })
      .catch((e) => {
        setError(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [modelArtifactId]);

  return [loading, error, data];
};
