import axios from 'axios';
import { baseURL } from 'src/context/url';
import Auth from 'src/auth/cognito';

export interface ApiRequestConfig {
  endpoint: string;
  params?: Record<string, string | undefined>;
  data?: Record<string, unknown>;
}

const auth = new Auth();

const mlPigeonAPI = axios.create({
  baseURL,
  headers: {
    Authorization: auth.getJwtToken(),
  },
});

export const apiGetRequest = ({ endpoint, params }: ApiRequestConfig) => {
  const filteredParams = Object.fromEntries(
    Object.entries(params || {}).filter(([, value]) => value !== undefined),
  );

  return mlPigeonAPI
    .get(
      `${endpoint}${
        Object.keys(filteredParams).length > 0
          ? `?${new URLSearchParams(filteredParams as any)}`
          : ''
      }`,
    )
    .then((res) => {
      if (res?.data?.message) {
        throw new Error(res.data.message);
      }
      return res.data;
    })
    .catch((error) => {
      if (error.request?.status === 401 || error.request?.status === 403) {
        window.location.href = '/unauthorized';
      }
      throw new Error(error.response?.data?.message || error.message);
    });
};

export const apiPutRequest = ({ endpoint, data }: ApiRequestConfig) =>
  mlPigeonAPI
    .put(`${endpoint}`, data)
    .then((res) => {
      if (res?.data?.message) {
        throw new Error(res.data.message);
      }
      return res.data;
    })
    .catch((error) => {
      if (error.request?.status === 401 || error.request?.status === 403) {
        window.location.href = '/unauthorized';
      }
      throw new Error(error.response?.data?.message || error.message);
    });

export const apiPostRequest = ({ endpoint, data }: ApiRequestConfig) =>
  mlPigeonAPI
    .post(`${endpoint}`, data)
    .then((res) => {
      if (res?.data?.message) {
        throw new Error(res.data.message);
      }
      return res.data;
    })
    .catch((error) => {
      if (error.request?.status === 401 || error.request?.status === 403) {
        window.location.href = '/unauthorized';
      }
      throw new Error(error.response?.data?.message || error.message);
    });

export const apiDeleteRequest = ({ endpoint, data }: ApiRequestConfig) =>
  mlPigeonAPI
    .delete(endpoint, data)
    .then((res) => {
      if (res?.data?.message) {
        throw new Error(res.data.message);
      }
      return res.data;
    })
    .catch((error) => {
      if (error.request?.status === 401 || error.request?.status === 403) {
        window.location.href = '/unauthorized';
      }
      throw new Error(error.response?.data?.message || error.message);
    });
