import React, { useEffect, useState } from 'react';
import { Link } from 'src/components/Link';
import { apiGetRequest } from 'src/utils/mlPigeonAPIRequest';

interface Props {
  shareJobId: string;
  succeeded: boolean;
}
export const SharedModelLink = ({ shareJobId, succeeded }: Props) => {
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    if (!shareJobId || !succeeded) return;

    const callForIds = async () => {
      try {
        const { modelVariationId, modelVariationVersionId } =
          await apiGetRequest({
            endpoint: `/shareModelJobs/${shareJobId}`,
          });

        const data = await apiGetRequest({
          endpoint: `sharedModelVariations/${modelVariationId}`,
        });
        setData({ ...data, modelVariationVersionId });
      } catch (e) {
        console.error(e);
      }
    };

    callForIds();
  }, [shareJobId, succeeded]);

  if (
    data?.modelId &&
    data?.modelVariationId &&
    data?.modelVariationVersionId
  ) {
    return (
      <>
        <Link
          to={`/modelRegistry/model/${data.modelId}/variation/${data.modelVariationId}`}
        >
          View Shared Model in Model Registry
        </Link>
        <span className="text-size-sm text-secondary">
          (version {data.modelVariationVersionId})
        </span>
      </>
    );
  }

  return <p className="text-disabled">View Shared Model in Model Registry</p>;
};
