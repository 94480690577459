import React, { ReactNode } from 'react';
import { KatIcon, KatLink } from '@amzn/katal-react';
import { useNavigate, useHref } from 'react-router-dom';

interface LinkProps {
  children: ReactNode;
  className?: string;
  to: string;
  external?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  newTab?: boolean;
}

export const Link = ({
  children,
  className,
  to,
  external,
  onClick,
  newTab,
}: LinkProps) => {
  const navigate = useNavigate();
  const relativeHref = useHref(to, { relative: 'path' });
  return (
    <KatLink
      className={className}
      href={external ? to : undefined}
      target={external ? '_blank' : undefined}
      rel={external ? 'noopener noreferrer' : undefined}
      onClick={(e) => {
        onClick?.(e);
        if (newTab && !external) {
          window.open(relativeHref, '_blank', 'noopener,noreferrer');
        } else if (!external) {
          e.preventDefault();
          navigate(`${to}`, { relative: 'path' });
        }
      }}
    >
      {children}
      {external && (
        <span className="ml-1">
          <KatIcon name="launch" size="tiny" />
        </span>
      )}
    </KatLink>
  );
};
