import React, { useContext, useEffect, useState } from 'react';
import { useLocalStorage } from '@uidotdev/usehooks';
import { KatButton, KatDropdown, KatInput, KatModal } from '@amzn/katal-react';
import {
  supportedEventTypes,
  supportedRegions,
  supportedRegionsWithNameValue,
} from 'src/context/apiState';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useTriggerWorkflow } from 'src/hooks/useTriggerWorkflow';
import { MultipleKeyValuePairInputField } from 'src/components/MultipleKeyValuePairInputField';
import { ApiContext } from 'src/context/apiContext';

export const TriggerWorkflowModal = ({
  values,
  onClose,
}: {
  values?: {
    startingOperatorType: string;
    startingOperatorName: string;
    variables: { key: string; value: string }[];
    region: string;
  };
  onClose: () => void;
}) => {
  const workflowTriggerValidationSchema = yup.object().shape({
    startingOperatorType: yup
      .string()
      .oneOf(
        supportedEventTypes.map((item) => item.value),
        'Please select the starting operator type.',
      )
      .required("Please select the starting operator's type."),

    startingOperatorName: yup
      .string()
      .required("Please enter the starting operator's name."),

    variables: yup.array().of(
      yup.object().shape({
        key: yup.string().required('Please enter a valid key.'),
        value: yup.string().required('Please enter a valid value.'),
      }),
    ),

    region: yup
      .string()
      .oneOf(
        supportedRegions.map((item) => item),
        'Please select a region.',
      )
      .required('Please select a region.'),
  });

  const initialValues = {
    startingOperatorType: 'RETRAINING',
    startingOperatorName: values?.startingOperatorName || '',
    variables: [],
    region: 'us-east-1',
  };

  const { state } = useContext(ApiContext);
  const [triggerConfig, setTriggerConfig] = useLocalStorage(
    `triggerWorkflowConfig-account-${state.search.curAccountNo}-mv-${state.search.curModelVersionId}`,
    initialValues,
  );
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [
    eventApiLoading,
    eventApiError,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    eventApiResponse,
    callTriggerWorkflowApi,
  ] = useTriggerWorkflow();

  useEffect(() => {
    setModalVisible(true);
  }, []);

  const formik = useFormik({
    initialValues: triggerConfig,
    onSubmit: async (values) => {
      setTriggerConfig(values);
      await callTriggerWorkflowApi({
        startingOperatorType: values.startingOperatorType,
        startingOperatorName: values.startingOperatorName,
        modelVersionId: state.search.curModelVersionId,
        region: values.region,
        variables: values.variables,
      }).then(onClose);
    },
    validationSchema: workflowTriggerValidationSchema,
  });

  return (
    <KatModal
      visible={modalVisible}
      onClose={() => {
        onClose();
      }}
      title="Workflow Triggering"
      footer={
        <div>
          <KatButton variant="link" onClick={onClose}>
            Cancel
          </KatButton>
          <KatButton
            loading={eventApiLoading}
            onClick={() => {
              formik.handleSubmit();
            }}
          >
            Submit
          </KatButton>
        </div>
      }
    >
      <div className="modal-form trigger-modal">
        <div>
          <p>Triggers a workflow that matches the following information</p>
          <form onSubmit={formik.handleSubmit}>
            <KatDropdown
              name="startingOperatorType"
              label="Starting Operator Type"
              value={
                values?.startingOperatorType ||
                formik.values.startingOperatorType
              }
              options={supportedEventTypes}
              placeholder="Select the starting operator's type"
              onChange={formik.handleChange}
              state={formik.errors.startingOperatorType ? 'error' : undefined}
              stateLabel={formik.errors.startingOperatorType}
            />
            <KatInput
              label="Starting Operator Name"
              value={
                values?.startingOperatorName ||
                formik.values.startingOperatorName
              }
              placeholder="my-operator-name"
              name="startingOperatorName"
              onChange={formik.handleChange}
              state={
                formik.touched.startingOperatorName &&
                formik.errors.startingOperatorName
                  ? 'error'
                  : undefined
              }
              stateLabel={formik.errors.startingOperatorName}
            />
            <KatDropdown
              name="region"
              label="Region"
              value={values?.region || formik.values.region}
              options={supportedRegionsWithNameValue}
              placeholder="Select a region"
              onChange={formik.handleChange}
              state={formik.errors.region ? 'error' : undefined}
              stateLabel={formik.errors.region}
            />
            <MultipleKeyValuePairInputField
              initialValue={values?.variables || formik.values.variables}
              addButtonText="Add Variables"
              label="Variables"
              name="variables"
              placeholder={['variable key', 'variable value']}
              errors={
                formik.errors.variables as { key: string; value: string }[]
              }
              onChange={(e) => {
                formik.handleChange(e);
              }}
            />
            {eventApiError && (
              <p className="error-text">{eventApiError.message}</p>
            )}
          </form>
        </div>
      </div>
    </KatModal>
  );
};
