import React, { ReactNode, useContext, useEffect } from 'react';
import { AccountContext } from 'src/context/accountContext';
import { Spinner } from '@cloudscape-design/components';

export const PrivateRoute = ({ children }: { children: ReactNode }) => {
  const { selectedAccount, signout, loading } = useContext(AccountContext);

  useEffect(() => {
    if (!loading && !selectedAccount) {
      signout();
    }
  }, [loading]);

  return loading ? <Spinner /> : children;
};
