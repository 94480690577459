import React, { ReactElement, useMemo, useState } from 'react';
import { TrainingJob } from 'src/context/apiState';

import { StatusCell } from 'src/components/StatusCell';
import { apiGetRequest } from 'src/utils/mlPigeonAPIRequest';
import { ClientSideTable } from 'src/components/ClientSideTable';
import { JSONViewer } from 'src/components/JSONViewer';
import { Link } from 'src/components/Link';
import { TrainingJobCell } from 'src/components/TrainingJobCell';
import { useParams } from 'react-router-dom';

export const TrainingJobTable = ({
  loading,
  data,
  error,
}: {
  loading: boolean;
  data: TrainingJob[] | null;
  metricNames: Set<string> | null;
  error: Error | null;
}): ReactElement => {
  const { accountNo, applicationId, modelVersionId, modelArtifactId } =
    useParams();
  return (
    <ClientSideTable
      sortingColumn={{
        sortingField: 'createdDate',
      }}
      sortingDescending
      resizableColumns
      variant="container"
      actions={
        <Link
          to={`/accounts/${accountNo}/applications/${applicationId}/modelVersions/${modelVersionId}/modelArtifacts/${modelArtifactId}/dataQualityMetrics`}
        >
          View all data quality metrics
        </Link>
      }
      columnDefinitions={[
        {
          id: 'Job',
          header: 'Training Job',
          width: 450,
          cell: (item) => (
            <TrainingJobCell
              trainingJobArn={item.trainingJobArn}
              trainingJobName={item.trainingJobName}
              createdDate={item.createdDate}
              id={item.id}
            />
          ),

          sortingField: 'id',
          isRowHeader: true,
        },
        {
          id: 'operator-flow-status',
          header: 'Operator Flow Status',
          width: 220,
          cell: (item) => <StatusCell value={item.operatorFlowJobStatus} />,
          sortingField: 'operatorFlowJobStatus',
          isRowHeader: true,
        },
        {
          id: 'createdDate',
          header: 'Created Date',
          cell: (item) => {
            const date = new Date(item.createdDate * 1000);
            return date.toUTCString();
          },
          sortingField: 'createdDate',
          isRowHeader: true,
        },
        {
          id: 'sagemaker-config',
          header: 'Sagemaker Config',
          width: 400,
          cell: (item) => {
            if (!item) return <></>;
            const [configData, setConfigData] = useState(null);
            const [loading, setLoading] = useState(true);
            const [error, setError] = useState<Error | null>(null);

            useMemo(() => {
              apiGetRequest({
                endpoint: `jobDetails?jobIds=${item.id}`,
              })
                .then((data) => {
                  let json;
                  try {
                    json = JSON.parse(data?.[0]?.sageMakerApiPayload);
                  } catch (e) {
                    setError(new Error('Invalid JSON'));
                    return;
                  }

                  setConfigData(json);
                })
                .catch((err) => {
                  setError(err);
                })
                .finally(() => {
                  setLoading(false);
                });
            }, [item.id]);

            if (error)
              return <span className="error-text">{error?.message}</span>;
            return <JSONViewer loading={loading} jsonData={configData} />;
          },
          isRowHeader: true,
        },
      ]}
      data={data ?? []}
      title={'Training Jobs'}
      error={error}
      loading={loading}
    />
  );
};
