import { QueryClient, useQuery } from '@tanstack/react-query';
import {
  apiDeleteRequest,
  apiGetRequest,
  apiPostRequest,
  apiPutRequest,
} from 'src/utils/mlPigeonAPIRequest';
import { urlList } from 'src/context/url';
import { ModelVersionEvent } from 'src/context/apiState';

interface Props {
  versionId: string | undefined;
}

export const useModelVersionEvents = ({ versionId }: Props) => {
  if (!versionId) return { data: [], isLoading: false };
  return useQuery<ModelVersionEvent[], Error>({
    queryKey: ['model-version-events', versionId],
    queryFn: () =>
      apiGetRequest({
        endpoint: urlList.modelVersionEvents,
        params: {
          modelVersionId: versionId,
        },
      }),
  });
};

type CreateModelVersionEventProps = {
  modelVersionEvent: Required<
    Pick<
      ModelVersionEvent,
      'id' | 's3Bucket' | 's3Prefix' | 'type' | 'operatorName'
    >
  >;
  modelVersionId: string;
};

export const createModelVersionEvent = (
  { modelVersionEvent, modelVersionId }: CreateModelVersionEventProps,
  queryClient: QueryClient,
) => {
  return apiPostRequest({
    endpoint: urlList.modelVersionEvents,
    data: {
      modelVersionId: modelVersionId,
      id: modelVersionEvent.id,
      s3Bucket: modelVersionEvent.s3Bucket,
      s3Prefix: modelVersionEvent.s3Prefix,
      type: modelVersionEvent.type,
      operatorName: modelVersionEvent.operatorName,
    },
  }).then(() => {
    queryClient.invalidateQueries({
      queryKey: ['model-version-events', modelVersionId],
    });
  });
};

export const editModelVersionEvent = (
  { modelVersionEvent, modelVersionId }: CreateModelVersionEventProps,
  queryClient: QueryClient,
) => {
  return apiPutRequest({
    endpoint: urlList.modelVersionEvents,
    data: {
      modelVersionId: modelVersionId,
      id: modelVersionEvent.id,
      s3Bucket: modelVersionEvent.s3Bucket,
      s3Prefix: modelVersionEvent.s3Prefix,
      type: modelVersionEvent.type,
      operatorName: modelVersionEvent.operatorName,
    },
  }).then(() => {
    queryClient.invalidateQueries({
      queryKey: ['model-version-events', modelVersionId],
    });
  });
};

export const deleteModelVersionEvent = (
  {
    modelVersionEventId,
    modelVersionId,
  }: { modelVersionEventId: string; modelVersionId: string },
  queryClient: QueryClient,
) => {
  return apiDeleteRequest({
    endpoint: `${urlList.modelVersionEvents}/${modelVersionEventId}`,
  }).then(() => {
    queryClient.invalidateQueries({
      queryKey: ['model-version-events', modelVersionId],
    });
  });
};
