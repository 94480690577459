import { SageMakerLink } from 'src/components/SageMakerLink';
import { ARN, build } from '@aws-sdk/util-arn-parser';
import { KatButton, KatIcon, KatSpinner } from '@amzn/katal-react';
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { RedeployConfig } from 'src/hooks/useModelRedeployment';
import { UserContext } from 'src/context/userContext';
import { fetchDeploymentJob } from 'src/components/fetchDeploymentJob';
import { RetryMenu } from 'src/components/RetryMenu';
import { RetryConfig } from 'src/hooks/useRetryOperator';
import { Link } from 'src/components/Link';
import { OperatorInfo } from 'src/components/OperatorInfo';
import { StatusLabel } from 'src/components/StatusLabel';

export interface DeploymentJobNodeProps {
  header: string;
  info: {
    id: string;
    operatorName: string;
    modelName?: string;
    modelArn?: ARN;
    operatorFlowId: string;
    region?: string;
  };
  status: OperatorJobStatus;
  arnList?: ARN[];
  setPromoteConfig: Dispatch<
    SetStateAction<{ modelName: string; arn: ARN } | null>
  >;
  setRedeployConfig: Dispatch<SetStateAction<RedeployConfig | null>>;
  setRetryConfig: (retryConfig: RetryConfig) => void;
  setOperatorHelpConfig: (arg: { status: OperatorJobStatus }) => void;
}

export const DeploymentJobNode = ({
  header,
  info,
  status,
  arnList,
  setPromoteConfig,
  setRedeployConfig,
  setRetryConfig,
  setOperatorHelpConfig,
}: DeploymentJobNodeProps) => {
  const user = useContext(UserContext);
  const [deploySetting, setDeploySetting] = useState<
    'Deploy' | 'Redeploy' | null
  >(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (status !== 'SUCCEEDED') return; // dont show any buttons if the job wasnt successful

    const checkDeploymentType = async () => {
      if (info.modelName?.startsWith('beta-')) {
        setLoading(true);
        await fetchDeploymentJob(info.id, user.token)
          .then((data) => {
            if (data?.stage === 'BETA') {
              setDeploySetting('Deploy');
            } else {
              setDeploySetting('Redeploy');
            }
          })
          .catch(setError)
          .finally(() => {
            setLoading(false);
          });

        return;
      }

      setDeploySetting('Redeploy'); // default to Redeploy
    };

    checkDeploymentType();
  }, [info.modelName]);

  const getRegionsFromArnList = (
    arns: ARN[] | undefined,
  ): string[] | undefined => {
    if (!arns) return [];

    return Array.from(
      arns.reduce((prev: Set<string>, arn: ARN) => {
        prev.add(arn.region.toString());
        return prev;
      }, new Set<string>()),
    );
  };

  const regions = getRegionsFromArnList(arnList);
  const jobTypeMap: Record<OperatorJobStatus, string> = {
    NOTSTARTED: 'Not Started',
    STARTING: 'Starting',
    PENDING: 'Pending',
    SUCCEEDED: 'Succeeded',
    FAILED: 'Failed',
  };
  const friendlyStatus: string = jobTypeMap[status];

  return (
    <div className="job-visualization-node">
      <div className="job-visualization-node__header">
        <h3>{header}</h3>
        <div className="job-visualization-node__status-container">
          <StatusLabel loading={false} label={friendlyStatus.toLowerCase()} />
          {status !== 'SUCCEEDED' && (
            <button
              className="icon-button"
              onClick={() => setOperatorHelpConfig({ status })}
              title="Troubleshooting"
            >
              <KatIcon size="small" name="help" />
            </button>
          )}
        </div>
      </div>
      <OperatorInfo
        operatorName={info.operatorName}
        operatorId={info.id}
        region={info.region}
      />
      <div className="job-visualization-node__links">
        {arnList?.map((arn) => <SageMakerLink key={build(arn)} arn={arn} />)}
        <Link to={`operatorHistory?operatorName=${info.operatorName}`}>
          View History
        </Link>
      </div>
      {error && <p className="error-text">{error.message}</p>}
      <div className="job-visualization-node__buttons">
        <div>
          {!deploySetting && loading && <KatSpinner size="small" />}
          {deploySetting === 'Redeploy' && (
            <KatButton
              disabled={false}
              size="small"
              onClick={() => {
                setRedeployConfig({
                  operatorName: info.operatorName,
                  rollbackOperatorFlowId: info.operatorFlowId,
                  regions,
                });
              }}
            >
              Redeploy
            </KatButton>
          )}
          {deploySetting === 'Deploy' && (
            <KatButton
              variant="primary"
              size="small"
              onClick={() => {
                setPromoteConfig({
                  modelName: info.modelName!,
                  arn: info.modelArn!,
                });
              }}
            >
              Deploy to Production
            </KatButton>
          )}
        </div>
        <div className="job-visualization-node__button-group">
          {status !== 'NOTSTARTED' && (
            <Link to={`../logs?operatorName=${info.operatorName}`} newTab>
              View Logs
            </Link>
          )}

          {status === 'FAILED' && (
            <RetryMenu
              nodeData={{ id: info.id, operatorName: info.operatorName }}
              setConfig={setRetryConfig}
            />
          )}
        </div>
      </div>
    </div>
  );
};
