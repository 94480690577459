import React from 'react';
import { CopyToClipboardButton } from 'src/components/CopyToClipboardButton';
import { DataTable } from 'src/components/DataTable';
import { KatIcon } from '@amzn/katal-react';

interface Props {
  loading: boolean;
  decisions: ApprovalStatus[];
}
export const DecisionTable = ({ loading, decisions }: Props) => {
  return (
    <div className="status-message">
      <DataTable
        disableFilter
        disablePagination
        loading={loading}
        options={{ slimHeader: true, noDataText: 'No decisions found.' }}
        columns={[
          {
            Header: 'Decision',
            accessor: 'status',
            width: '65',
            Cell: (cellInfo: any) => {
              if (!cellInfo.value) return <></>;
              const isSuccess = cellInfo.value === 'SUCCEEDED';
              return (
                <div
                  className={`flex gap-2 ${
                    isSuccess ? 'text-green-500' : 'text-red-500'
                  }`}
                >
                  <KatIcon
                    name={`${isSuccess ? 'checkmark-circle' : 'error'}`}
                    size="small"
                  />
                  <span className="text-black font-bold">{`${
                    isSuccess ? 'Approved' : 'Rejected'
                  }`}</span>
                </div>
              );
            },
          },
          {
            Header: 'Message',
            accessor: 'statusMessage',
            Cell: (cellInfo: any) => {
              if (!cellInfo.value) return <></>;
              return (
                <div>
                  <div className="max-h-12 overflow-auto">
                    <p>
                      {cellInfo.value}
                      <CopyToClipboardButton
                        icon="content_copy"
                        variant="link"
                        data={cellInfo.value}
                      />
                    </p>
                  </div>
                </div>
              );
            },
          },
          {
            Header: 'Username',
            accessor: 'createdBy',
            Cell: (cellInfo: any) => {
              return (
                <div className="" style={{ pointerEvents: 'all' }}>
                  <div className="max-h-12 overflow-auto">
                    <p>
                      {cellInfo.value}
                      <CopyToClipboardButton
                        icon="content_copy"
                        variant="link"
                        data={cellInfo.value}
                      />
                    </p>
                  </div>
                </div>
              );
            },
          },
        ]}
        data={decisions}
      />
    </div>
  );
};
