import React from 'react';
import { ContentLayout } from 'src/components/ContentLayout';
import { Link } from 'src/components/Link';

export const FileNotFoundPage = () => (
  <ContentLayout
    heading={<h1>Page Not Found</h1>}
    description="No page matching this url was found. Please click the link below to return to the account homepage."
  >
    <Link to="/">Return to the homepage</Link>
  </ContentLayout>
);
