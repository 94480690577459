import { KatAlert } from '@amzn/katal-react';
import { ReactNode } from 'react';

export interface ApiState {
  notificationEmailAddresses: EmailAddress[];
  modelArtifacts: ErrorWrapper<ModelArtifact[]>;
  modelVersions: ErrorWrapper<ModelVersion[]>;
  modelVersionEvents: ErrorWrapper<ModelVersionEvent[]>;
  applications: ErrorWrapper<Application[]>;
  dataQualityMetrics: ErrorWrapper<ModelMetric[]>;
  operatorFlowJobs: ErrorWrapper<OperatorFlowJob[]>;
  search: {
    accounts: ErrorWrapper<Account[]>;
    onboardableAccounts: ErrorWrapper<Account[]>;
    curOnboardableAccountNo: string;
    curAccountNo: string;
    curRoleName: string;
    workflowOnboardingRequestStatus: RequestStatus;
    curApplicationId: string;
    curModelVersionId: string;
    curModelVersionEventId: string;
    curModelArtifactId: string;
    curTrainingJobId: string;
    curOperatorFlowJobId: string;
    curOperatorFlowJobStartTime: number;
    curOperatorFlowJobEndTime: number;
  };
  toasts: IToastMessage[];
}

export interface AWSAccountDetails {
  id: string;
  name?: string;
  region?: string;
  awsAccountType: string;
}

interface Account {
  name: string;
  id: string;
  type?: string;
}

interface RequestStatus {
  state: number;
  statusMessage: string;
}

interface EmailAddress {
  address: string;
}

interface ModelArtifact {
  id: string;
  modelVersionId: string;
  modelArtifactName: string;
  awsAccount: string;
  tags: string;
}

export interface TrainingJob {
  id: string;
  modelArtifactId: string;
  awsAccountId?: string;
  trainingJobArn: string;
  s3TrainingDatasetLocation: string;
  s3ArtifactsLocation: string;
  status: string;
  deploymentJobIds?: string;
  createdDate: string;
  createdBy: string;
  updatedDate: string;
  updatedBy: string;
  metrics: Metric[];
}

export interface MonitoringSchedule {
  id: string;
  modelArtifactId: string;
  monitoringScheduleArn: string;
  monitoringScheduleName: string;
  status: string;
  operatorFlowJobId?: string;
  operatorName?: string;
  upstreamJobRelationships?: { jobId: string; jobType: string }[];
  tags?: Record<string, string>;
  createdDate: string;
  createdBy: string;
  updatedDate: string;
  updatedBy: string;
}

export interface ModelVersionEvent {
  id?: string;
  modelVersionId: string;
  type?: ModelVersionEventType;
  s3Bucket?: string;
  s3Prefix?: string;
  region?: string;
  operatorName?: string;
  createdDate?: number;
  createdBy?: string;
  updatedDate?: number;
  updatedBy?: string;
  tags?: Record<string, string>;
}

export enum ModelVersionEventType {
  BATCHINFERENCE = 'BATCHINFERENCE',
  RETRAINING = 'RETRAINING',
  MONITORING = 'MONITORING',
  PROCESSING = 'PROCESSING',
  MODELDEPLOYMENT = 'MODELDEPLOYMENT',
  HYPERPARAMETERTUNING = 'HYPERPARAMETERTUNING',
  MONITORINGSCHEDULE = 'MONITORINGSCHEDULE',
}

export interface ModelMetric {
  jobId: string;
  metricsType: string;
  jobType: string;
  modelArtifactId: string;
  trainingJobId: string;
  sageMakerModelArn: string;
  datasetLocation: string;
  awsAccount: string;
  applicationId: string;
  modelVersionId: string;
  metrics: string;
  metricsS3Path: string;
  constraintS3Path: string;
  constraintViolations: string;
  constraintViolationS3Path: string;
  createdDate: string;
}

interface ErrorWrapper<T> {
  data: T;
  error: string;
}

export interface IToastMessage {
  id: string;
  header: string;
  description: ReactNode;
  onClick?: (event: MouseEvent | any) => void;
  onDismiss?: (e: KatAlert.DismissEvent) => void;
  onTimeout?: () => void;
  variant?: KatAlert.Variant;
  dismissTime?: number;
  held?: boolean;
}

export interface Dataset {
  label: string;
  ids: string[];
  data: (string | undefined)[];
  pointHoverRadius: number;
  pointRadius: number[];
  borderColor: string;
  backgroundColor: string;
}

export interface ChartData {
  labels: string[];
  datasets: Dataset[];
}

export const supportedRegions = [
  'us-east-1',
  'us-west-2',
  'eu-west-1',
  'ap-south-1',
  'eu-south-2',
  'eu-central-1',
  'ap-northeast-1',
  'ap-south-2',
];

export const supportedRegionsWithNameValue = supportedRegions.map((region) => ({
  name: region,
  value: region,
}));

export const supportedEventTypes: Record<string, string>[] = [
  {
    name: 'Retraining',
    value: 'RETRAINING',
  },
  {
    name: 'Batch Inference',
    value: 'BATCHINFERENCE',
  },
  {
    name: 'Processing',
    value: 'PROCESSING',
  },
  {
    name: 'Model Customization',
    value: 'MODELCUSTOMIZATIONJOB',
  },
  {
    name: 'Model Deployment',
    value: 'MODELDEPLOYMENT',
  },
  {
    name: 'Hyper Parameter Tuning',
    value: 'HYPERPARAMETERTUNING',
  },
  {
    name: 'Labeling',
    value: 'LABELING',
  },
  {
    name: 'Monitoring Schedule',
    value: 'MONITORINGSCHEDULE',
  },
  {
    name: 'Inline Code',
    value: 'INLINECODE',
  },
  {
    name: 'SageMaker Pipeline Execution',
    value: 'SAGEMAKERPIPELINEEXECUTION',
  },
  {
    name: 'Share Model',
    value: 'SHAREMODEL',
  },
  {
    name: 'Consume Shared Model',
    value: 'CONSUMESHAREDMODEL',
  },
];
