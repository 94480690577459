import { parse } from '@aws-sdk/util-arn-parser';
import { SageMakerLink } from 'src/components/SageMakerLink';
import { Link, useParams } from 'react-router-dom';
import React from 'react';

export const TrainingJobCell = ({
  createdDate,
  trainingJobArn,
  trainingJobName,
  id,
}: {
  createdDate?: number;
  trainingJobArn: string;
  trainingJobName: string;
  id: string;
}) => {
  let arn;
  try {
    arn = parse(trainingJobArn);
  } catch {
    arn = null;
  }

  const { accountNo, applicationId, modelVersionId, modelArtifactId } =
    useParams();
  return (
    <div className="training-job-cell">
      <div>
        <p className="training-job-cell__id">{id}</p>
        <p>{trainingJobName}</p>
      </div>
      <div className="training-job-cell__link-group">
        {arn && <SageMakerLink arn={arn} />}
        <Link
          to={`/accounts/${accountNo}/applications/${
            applicationId
          }/modelVersions/${modelVersionId}/modelArtifacts/${
            modelArtifactId
          }/trainingJobs/${id}/dataQualityMetrics${
            createdDate
              ? `?startDate=${new Date(
                  createdDate * 1000,
                ).toUTCString()}&endDate=${new Date(
                  createdDate * 1000,
                ).toUTCString()}`
              : ''
          }`}
        >
          View Data Quality Metrics
        </Link>
      </div>
    </div>
  );
};
