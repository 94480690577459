/* istanbul ignore file */
import uniqueId from 'lodash/uniqueId';
import { IToastMessage } from 'src/context/apiState';

export const LOGIN_USER = 'loginUser';
export const UPDATE_ACCOUNTS_DATA = 'updateAccountsData';
export const UPDATE_APPLICATIONS_DATA = 'updateApplicationsData';
export const UPDATE_MODEL_VERSIONS_DATA = 'updateModelVersionsData';
export const UPDATE_MODEL_ARTIFACTS_DATA = 'updateModelArtifactsData';
export const UPDATE_MODEL_VERSION_EVENTS_DATA = 'updateModelVersionEventsData';
export const UPDATE_OPERATOR_FLOW_JOB_DATA = 'updateOperatorFlowJobData';
export const UPDATE_OPERATOR_FLOW_JOB_DETAILS_DATA =
  'updateOperatorFlowJobDetailsData';
export const SET_CURRENT_ACCOUNT_NO = 'setCurrentAccountNo';
export const SET_CURRENT_APPLICATION_ID = 'setCurrentApplicationId';
export const SET_CURRENT_MODEL_VERSION_ID = 'setCurrentModelVersionId';
export const SET_CURRENT_MODEL_ARTIFACT_ID = 'setCurrentModelArtifactId';
export const SET_CURRENT_MODEL_VERSION_EVENT_ID =
  'setCurrentModelVersionEventId';
export const SET_CURRENT_OPERATOR_FLOW_JOB_ID = 'setCurrentOperatorFlowJobId';
export const ADD_TOAST = 'addToast';
export const TIMEOUT_REMOVAL_CHECK_TOAST = 'timeoutRemovalCheckToast';
export const REMOVE_TOAST = 'removeToast';
export const HOLD_TOAST = 'holdToast';

export const reducer = (state: any, action: any) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        isAuthenticated: action.payload.authenticated,
        name: action.payload.user,
        token: action.payload.token,
      };

    case UPDATE_ACCOUNTS_DATA:
      return {
        ...state,
        search: {
          ...state.search,
          accounts: action.payload,
        },
      };

    case UPDATE_APPLICATIONS_DATA:
      return {
        ...state,
        applications: action.payload,
      };

    case UPDATE_MODEL_VERSIONS_DATA:
      return {
        ...state,
        modelVersions: action.payload,
      };

    case UPDATE_MODEL_ARTIFACTS_DATA:
      return {
        ...state,
        modelArtifacts: action.payload,
      };

    case UPDATE_MODEL_VERSION_EVENTS_DATA:
      return {
        ...state,
        modelVersionEvents: action.payload,
      };

    case UPDATE_OPERATOR_FLOW_JOB_DATA:
      return {
        ...state,
        operatorFlowJobs: action.payload,
      };

    case UPDATE_OPERATOR_FLOW_JOB_DETAILS_DATA:
      return {
        ...state,
        operatorFlowJobDetails: action.payload,
      };

    case SET_CURRENT_ACCOUNT_NO:
      return {
        ...state,
        search: {
          ...state.search,
          curAccountNo: action.payload,
        },
      };

    case SET_CURRENT_APPLICATION_ID:
      return {
        ...state,
        search: {
          ...state.search,
          curApplicationId: action.payload,
        },
      };

    case SET_CURRENT_MODEL_VERSION_ID:
      return {
        ...state,
        search: {
          ...state.search,
          curModelVersionId: action.payload,
        },
      };

    case SET_CURRENT_MODEL_VERSION_EVENT_ID:
      return {
        ...state,
        search: {
          ...state.search,
          curModelVersionEventId: action.payload,
        },
      };

    case SET_CURRENT_MODEL_ARTIFACT_ID:
      return {
        ...state,
        search: {
          ...state.search,
          curModelArtifactId: action.payload.modelArtifactId,
          curOperatorFlowJobStartTime: action.payload.startTime,
          curOperatorFlowJobEndTime: action.payload.endTime,
        },
      };

    case SET_CURRENT_OPERATOR_FLOW_JOB_ID:
      return {
        ...state,
        search: {
          ...state.search,
          curOperatorFlowJobId: action.payload,
        },
      };

    // Adds a new toast message, generating a new id for it
    case ADD_TOAST:
      return {
        ...state,
        toasts: [
          ...state.toasts,
          {
            id: uniqueId('toast-'),
            held: false,
            ...action.payload,
          },
        ],
      };

    // sets the held property of a Toast to true
    case HOLD_TOAST:
      return {
        ...state,
        toasts: state.toasts.map((toast: IToastMessage) => {
          if (toast.id === action.payload.id) {
            return { ...toast, held: true };
          }
          return toast;
        }),
      };

    // triggered by the portal on autoClose messages, removes the message unless its being held
    case TIMEOUT_REMOVAL_CHECK_TOAST:
      return {
        ...state,
        toasts: state.toasts.filter(
          (toast: IToastMessage) =>
            toast.id !== action.payload.id || toast.held,
        ),
      };

    // removes a toast message from the list of toasts
    case REMOVE_TOAST:
      return {
        ...state,
        toasts: state.toasts.filter(
          (toast: IToastMessage) => toast.id !== action.payload.id,
        ),
      };

    default:
      return state;
  }
};
