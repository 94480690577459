import {
  Box,
  Button,
  ButtonDropdown,
  Modal,
  SpaceBetween,
} from '@cloudscape-design/components';
import React, { useContext, useEffect } from 'react';
import { useGetAccounts } from 'src/hooks/useGetAccounts';
import { ClientSideTable } from 'src/components/ClientSideTable';
import { AccountContext } from 'src/context/accountContext';

export const AccountSelectionModal = ({
  visible,
  onDismiss,
}: {
  visible: boolean;
  onDismiss: () => void;
}) => {
  const [loading, error, data, getAccounts] = useGetAccounts();
  const { signin, recentAccounts } = useContext(AccountContext);

  useEffect(() => {
    if (visible) {
      getAccounts();
    }
  }, [visible]);

  const [selectedItems, setSelectedItems] = React.useState<any>([]);
  return (
    <Modal
      header="Select Account"
      visible={visible}
      size="large"
      onDismiss={onDismiss}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={() => onDismiss()}>
              Cancel
            </Button>
            <Button
              variant="primary"
              disabled={!!error || selectedItems.length === 0}
              onClick={() => {
                signin(selectedItems[0]);
                onDismiss();
              }}
            >
              Select Account
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <ClientSideTable
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <ButtonDropdown
              disabled={!recentAccounts || recentAccounts?.length === 0}
              onItemClick={(item) => {
                const account = recentAccounts.find(
                  (recentAccount) => recentAccount.id === item.detail.id,
                );
                if (account) {
                  signin(account);
                  onDismiss();
                }
              }}
              items={
                recentAccounts?.map((account) => ({
                  text: account.name,
                  id: account.id,
                  disabled: false,
                })) ?? []
              }
            >
              Recent Accounts
            </ButtonDropdown>
          </SpaceBetween>
        }
        title="AWS Accounts"
        data={data ?? []}
        error={error}
        loading={loading}
        onSelectionChange={({ detail }) => {
          setSelectedItems(detail.selectedItems);
        }}
        selectedItems={selectedItems}
        columnDefinitions={[
          {
            id: 'account-name',
            header: 'Account name',
            cell: (item) => item.name,
            sortingField: 'name',
            isRowHeader: true,
          },
          {
            id: 'account-number',
            header: 'Account number',
            cell: (item) => item.id,
            sortingField: 'id',
          },
          {
            id: 'account-type',
            header: 'Account type',
            cell: (item) => item.type,
            sortingField: 'type',
          },
        ]}
        loadingText="Loading accounts"
        selectionType="single"
        trackBy="id"
      />
    </Modal>
  );
};
