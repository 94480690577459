import { KatIcon } from '@amzn/katal-react';
import dagre from 'dagre';
import React from 'react';
import ReactFlow, {
  ControlButton,
  Controls,
  isNode,
  useReactFlow,
} from 'react-flow-renderer';

export const GraphDiagram = ({
  elements,
  goFullScreen,
}: {
  elements: any;
  goFullScreen?: () => Promise<void> | undefined;
}) => {
  const nodeWidth = 352;
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const { fitView } = useReactFlow();

  const getLaidOutElements = () => {
    dagreGraph.setGraph({ rankdir: 'TB' });
    elements.forEach((el: any) => {
      if (isNode(el)) {
        dagreGraph.setNode(el.id, {
          // @ts-ignore
          width: el.nodeWidth || nodeWidth,
          // @ts-ignore
          height: el.nodeHeight,
        });
      } else {
        dagreGraph.setEdge(el.source, el.target);
      }
    });
    dagre.layout(dagreGraph);
    return elements.map((el: any) => {
      if (isNode(el)) {
        const nodeWithPosition = dagreGraph.node(el.id);
        el.position = {
          // @ts-ignore
          x: el.nodeWidth
            ? // @ts-ignore
              nodeWithPosition?.x - el.nodeWidth / 2
            : nodeWithPosition?.x - nodeWidth / 2,
          // @ts-ignore
          y: nodeWithPosition?.y - el.nodeHeight / 2,
        };
      }
      return el;
    });
  };

  const els = getLaidOutElements();

  return (
    <ReactFlow
      fitView
      nodes={els.filter((el: any) => isNode(el))}
      edges={els.filter((el: any) => !isNode(el))}
      elementsSelectable={false}
      nodesConnectable={false}
      nodesDraggable={false}
      // @ts-ignore
      deleteKeyCode={null}
    >
      <Controls showZoom={true} showFitView={false} showInteractive={false}>
        <ControlButton
          onClick={() => {
            /* istanbul ignore next */
            fitView();
          }}
        >
          <div>
            <KatIcon name="location_searching" size="small" />
          </div>
        </ControlButton>
        {goFullScreen && (
          <ControlButton
            onClick={() => {
              /* istanbul ignore next */
              goFullScreen()?.then(() => {
                setTimeout(fitView, 300);
              });
            }}
          >
            <div>
              <KatIcon name="fullscreen" size="small" />
            </div>
          </ControlButton>
        )}
      </Controls>
    </ReactFlow>
  );
};
