import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { initialState } from 'src/context/initialState';
import { UserContextProvider } from 'src/context/userContext';
import { ApiContextProvider } from 'src/context/apiContext';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { router } from './routes';

import './index.scss';
import '../node_modules/@cloudscape-design/global-styles/index.css';

import '@tanstack/react-query';

declare module '@tanstack/react-query' {
  interface Register {
    defaultError: Error;
  }
}

const container = document.getElementById('root');
if (!container) {
  console.error('Missing #root element in DOM');
} else {
  const root = createRoot(container);

  const queryClient = new QueryClient();

  root.render(
    <StrictMode>
      <UserContextProvider value={initialState.user}>
        <ApiContextProvider value={initialState.apiData}>
          <QueryClientProvider client={queryClient}>
            <RouterProvider router={router} />
          </QueryClientProvider>
        </ApiContextProvider>
      </UserContextProvider>
    </StrictMode>,
  );
}
