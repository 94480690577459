import React, { ReactElement } from 'react';
import { Link } from 'src/components/Link';
import { ARN } from '@aws-sdk/util-arn-parser';

export const SageMakerLink = ({ arn }: { arn: ARN }): ReactElement => {
  const linkTextMap: Record<string, string> = {
    'processing-job': 'Processing Job',
    model: 'Model',
    'transform-job': 'Transform Job',
    endpoint: 'Endpoint',
    'training-job': 'Training Job',
    'hyper-parameter-tuning-job': 'Hyperparameter Tuning Job',
    'labeling-job': 'Labeling Job',
  };

  const [resourceType, resourceName] = arn.resource.split('/');
  let processedResourceType;

  switch (resourceType) {
    case 'training-job':
      processedResourceType = `jobs/${resourceName}`;
      break;
    case 'hyper-parameter-tuning-job':
      processedResourceType = `hyper-tuning-jobs/${resourceName}`;
      break;
    case 'labeling-job':
      processedResourceType = `labeling-jobs/details/${resourceName}`;
      break;
    default:
      processedResourceType = `${resourceType}s/${resourceName}`;
  }

  const href = `https://console.aws.amazon.com/sagemaker/${
    resourceType === 'labeling-job' ? 'groundtruth' : 'home'
  }?region=${arn.region}#/${processedResourceType}`;

  return (
    <Link to={href} external>
      View SageMaker {linkTextMap[resourceType]}
    </Link>
  );
};
