import React, { ReactNode, useState } from 'react';
import { TrainingMetricsChart } from 'src/components/TrainingMetricsChart';
import { JobDetailPane } from 'src/components/JobDetailPane';

export const MetricsChart = ({
  heading,
  loading,
  error,
  data,
  metricNames,
  trainingName,
}: {
  heading: ReactNode;
  loading: boolean;
  error: Error | null;
  data?: MetricsData[] | null;
  metricNames: Set<string> | null;
  trainingName?: string;
}) => {
  const [selectedJobId, setSelectedJobId] = useState<string>('');

  return (
    <section className="metrics-chart">
      <div className="metrics-chart__content">
        <TrainingMetricsChart
          heading={heading}
          loading={loading}
          data={data || null}
          error={error}
          metricNames={metricNames}
          setSelectedJobId={setSelectedJobId}
          trainingName={trainingName}
        />

        {metricNames && (
          <JobDetailPane
            job={data?.find((item) => item?.id === selectedJobId)}
            noSelectedJobText="Select a data point to view job information"
          />
        )}
      </div>
    </section>
  );
};
