import React, { useEffect, useState } from 'react';
import { KatButton, KatIcon, KatInput, KatLabel } from '@amzn/katal-react';
import uniqueId from 'lodash/uniqueId';
import { KeyValuePairInput } from 'src/components/KeyValuePairInput';

interface KeyValueField {
  id: string;
  placeholder?: string[];
  touched: boolean;
  value: {
    key: string;
    value: string;
  };
}

export const MultipleKeyValuePairInputField = ({
  addButtonText,
  disabled,
  errors,
  label,
  name,
  onChange,
  placeholder,
  initialValue,
}: {
  addButtonText: string;
  disabled?: boolean;
  errors?: { key?: string; value?: string }[];
  label: string;
  name: string;
  onChange?: (arg: KatInput.ChangeEvent) => void;
  placeholder?: string[];
  initialValue?: { key: string; value: string }[];
}) => {
  const [fields, setFields] = useState<KeyValueField[]>(
    initialValue?.map((item) => ({
      id: uniqueId(),
      value: item,
      placeholder,
      touched: false,
    })) ?? [],
  );

  const setFieldValue = (id: string, value: { key: string; value: string }) => {
    setFields(
      fields.map((field) => {
        if (field.id === id) {
          return { ...field, value, touched: true };
        }
        return field;
      }),
    );
  };

  const getValues = (): { key: string; value: string }[] =>
    fields.reduce(
      (
        prev: { key: string; value: string }[],
        current,
      ): { key: string; value: string }[] =>
        current.value ? [...prev, current.value] : [...prev],
      [],
    );

  const removeField = (id: any) => {
    setFields(fields.filter((field: any) => field.id !== id));
  };

  useEffect(() => {
    onChange?.({
      target: {
        // @ts-expect-error
        name,
        value: getValues(),
      },
    });
  }, [fields]);

  const addField = () => {
    const newId = uniqueId();
    setFields([
      ...fields,
      {
        id: newId,
        value: { key: '', value: '' },
        touched: false,
        placeholder: [],
      },
    ]);
  };

  return (
    <>
      <div className="multiple-key-value-input-field">
        <KatLabel className="multiple-key-value-input-field__label">
          {label}
        </KatLabel>
        {fields.map((field, index) => (
          <div
            className="multiple-key-value-input-field__field-container"
            key={field.id}
          >
            <div className="multiple-key-value-input-field__field">
              <KeyValuePairInput
                value={[field.value?.key, field.value?.value]}
                name={`input-${field.id}`}
                placeholder={placeholder}
                onChange={(e: KatInput.ChangeEvent) => {
                  // @ts-ignore
                  setFieldValue(field.id, e.target?.value);
                }}
                state={errors?.[index] ? 'error' : undefined}
                stateLabel={errors?.[index]?.key || errors?.[index]?.value}
              />
            </div>
            <button
              type="button"
              className="icon-button"
              title="remove field"
              onClick={() => {
                removeField(field.id);
              }}
            >
              <KatIcon name="trash" size="small" />
            </button>
          </div>
        ))}
      </div>
      <KatButton
        disabled={disabled}
        onClick={() => {
          addField();
        }}
        variant="secondary"
      >
        {addButtonText}
      </KatButton>
    </>
  );
};
