import React, { ReactNode, useEffect, useMemo } from 'react';
import {
  Row,
  useGlobalFilter,
  usePagination,
  useFilters,
  useSortBy,
  useTable,
  useFlexLayout,
  useResizeColumns,
  useRowSelect,
  Cell,
} from 'react-table';
import cx from 'classnames';
import {
  KatCheckbox,
  KatDropdown,
  KatIcon,
  KatInput,
  KatPagination,
} from '@amzn/katal-react';
import { uniqueId } from 'lodash';
import { CopyToClipboardButton } from 'src/components/CopyToClipboardButton';

export interface DataTableProps {
  disabled?: boolean;
  columns: any[];
  loading?: boolean;
  data: any[];
  heading?: ReactNode;
  description?: ReactNode;
  controls?: ReactNode;
  selectedRows?: Row[];
  setSelectedRows?: (selectedRows: Row[]) => void;
  disableFilter?: boolean;
  disablePagination?: boolean;
  globalFilter?: string;
  setGlobalFilter?: (arg: string) => void;
  highLightedRow?: { columnId: string; value: string };
  visibleColumns?: string[];
  options?: {
    constraintText?: string;
    className?: string;
    clipboardCopy?: boolean;
    noDataText?: string;
    defaultSort?: { id: string; desc: boolean };
    defaultPageSize?: number;
    onFetchData?: () => void;
    onSortedChange?: () => void;
    loadingText?: string;
    slimHeader?: boolean;
    onPageChange?: () => void;
    onPageSizeChange?: () => void;
  };
}

export const DataTable = ({
  disabled = false,
  columns,
  loading,
  data,
  heading,
  description,
  controls,
  highLightedRow,
  visibleColumns,
  options,
  selectedRows,
  setSelectedRows,
  disableFilter,
  disablePagination,
  globalFilter,
  setGlobalFilter,
}: DataTableProps) => {
  const initialState = {
    pageSize: options?.defaultPageSize || 10,
    globalFilter,
    hiddenColumns: visibleColumns
      ? columns.reduce((prev, curr) => {
          if (!visibleColumns?.includes(curr.id)) {
            prev.push(curr.id);
          }
          return prev;
        }, [])
      : [],
  };

  const tableOptions = useMemo(
    (): any => ({
      columns,
      data,
      state: {
        selectedRowIds: selectedRows
          ? selectedRows.reduce((acc: Record<string, boolean>, row: Row) => {
              acc[row.id] = true;
              return acc;
            }, {})
          : [],
      },
      initialState,
      autoResetPage: false,
      autoResetSortBy: false,
      autoResetSelectedRows: false,
      autoResetSelectedCell: false,
      autoResetSelectedColumn: false,
    }),
    [columns, data, initialState],
  );

  const tableInstance: any = useTable(
    tableOptions,
    useFilters,
    useGlobalFilter,
    useFlexLayout,
    useResizeColumns,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      setSelectedRows &&
        hooks.visibleColumns.push((columns) => [
          {
            id: 'select-col',
            width: 45,
            disableResizing: true,
            Header: (table) => {
              // @ts-ignore
              const props = table.getToggleAllRowsSelectedProps();

              return (
                <KatCheckbox
                  checked={props.checked}
                  onChange={props.onChange}
                />
              );
            },
            Cell: ({ row }: { row: any }) => {
              const props = row?.getToggleRowSelectedProps();
              return (
                <KatCheckbox
                  checked={props.checked}
                  onChange={props.onChange}
                />
              );
            },
          },
          ...columns,
        ]);
    },
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;

  useEffect(() => {
    if (loading) return;
    if (options?.onFetchData) {
      options.onFetchData();
    }
    if (options?.defaultSort) {
      tableInstance.toggleSortBy(
        options.defaultSort.id,
        options.defaultSort.desc,
      );
    }
  }, [loading, data]);

  useEffect(() => {
    if (!tableInstance.state.selectedRowIds) return;

    const selectedRowsArray = data.filter((row, index) => {
      const rowId = row.id || index;
      return tableInstance.state.selectedRowIds[rowId];
    });

    setSelectedRows?.(selectedRowsArray);
  }, [tableInstance.state.selectedRowIds]);

  const rowToHighlight = highLightedRow
    ? rows?.find((row: Row) => {
        prepareRow(row);
        return row.cells.find(
          (cell: Cell) =>
            cell.column.id === highLightedRow.columnId &&
            cell.value === highLightedRow.value,
        );
      })
    : undefined;

  return (
    <div
      className={cx(`data-table ${options?.className || ''}`, {
        'data-table--disabled': disabled,
      })}
    >
      <div className="data-table__header-container">
        <div className="data-table__header">
          {heading && <div className="data-table__heading">{heading}</div>}
          {description && (
            <div className="data-table__description">{description}</div>
          )}
        </div>
        <div className="data-table__header-controls">
          {!disableFilter && (
            <div>
              <KatInput
                label="Filter"
                placeholder="id, text content, etc."
                onInput={(e: KatInput.InputEvent) => {
                  setGlobalFilter
                    ? // @ts-expect-error
                      setGlobalFilter(e.target.value)
                    : // @ts-expect-error
                      tableInstance.setGlobalFilter(e.target.value);
                }}
              />
            </div>
          )}
          {controls}
        </div>
      </div>
      <div className="data-table__contents">
        <div className="data-table__table" {...getTableProps()}>
          <div
            className={`data-table__head ${
              options?.slimHeader ? 'data-table__head--slim' : ''
            } `}
          >
            {headerGroups.map((headerGroup: any) => {
              return (
                <div
                  className="data-table__row"
                  {...headerGroup.getHeaderGroupProps()}
                  key={uniqueId()}
                >
                  {headerGroup.headers.map((column: any) => {
                    return (
                      <div
                        key={uniqueId()}
                        className="data-table__cell"
                        {...column.getHeaderProps()}
                      >
                        <div className="data-table__header-cell-container">
                          <div className="data-table__header-cell">
                            <div className="header-cell__upper">
                              {column.render('Header')}

                              <span>
                                {column.dateControl &&
                                  column.render('dateControl')}
                                {column.sortable && (
                                  <KatIcon
                                    {...column.getSortByToggleProps()}
                                    name={
                                      column.isSorted
                                        ? column.isSortedDesc
                                          ? 'arrow_drop_down'
                                          : 'arrow_drop_up'
                                        : 'sort'
                                    }
                                    size="small"
                                  />
                                )}
                              </span>
                            </div>
                            {column.filterable && (
                              <div className="data-table__header-cell-filter">
                                {column.render('Filter')}
                              </div>
                            )}
                          </div>
                          {!column.disableResizing && (
                            <div
                              {...column.getResizerProps()}
                              className={`resizer ${
                                column.isResizing ? 'isResizing' : ''
                              }`}
                            />
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
          {data.length > 0 && page ? (
            <div className="data-table__body" {...getTableBodyProps()}>
              {rowToHighlight && (
                <>
                  <div
                    className="data-table__row data-table__row--highlight"
                    {...rowToHighlight.getRowProps()}
                  >
                    {rowToHighlight.cells.map((cell: Cell) => {
                      return (
                        <div
                          className="data-table__cell"
                          {...cell.getCellProps()}
                          key={uniqueId()}
                        >
                          {
                            // Render the cell contents
                            cell.render('Cell')
                          }
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
              {
                // Loop over the table rows
                page.map((row: Row<{}>) => {
                  prepareRow(row);
                  return (
                    <div
                      className={`data-table__row ${
                        row.id === rowToHighlight?.id ? 'hidden' : ''
                      }`}
                      {...row.getRowProps()}
                      key={uniqueId()}
                    >
                      {row.cells.map((cell) => {
                        return (
                          <div
                            className="data-table__cell"
                            {...cell.getCellProps()}
                            key={uniqueId()}
                          >
                            {
                              // Render the cell contents
                              cell.render('Cell')
                            }
                          </div>
                        );
                      })}
                    </div>
                  );
                })
              }
            </div>
          ) : (
            <div>
              <div>
                <div className="data-table__empty">
                  {loading
                    ? options?.loadingText || 'Loading Data...'
                    : options?.noDataText || 'No Data'}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="data-table__footer">
        {!disablePagination && (
          <div className="data-table__footer-controls">
            <div className="data-table__pagination">
              <KatPagination
                itemsPerPage={pageSize}
                page={pageIndex + 1}
                totalItems={rows.length}
                onChange={(e: KatPagination.ChangeEvent) => {
                  gotoPage(Number(e.detail.page - 1));
                }}
              />
            </div>
            <KatDropdown
              value={pageSize}
              onChange={(e: KatDropdown.ChangeEvent) => {
                setPageSize(Number(e.detail.value));
              }}
              options={['10', '20', '30', '40', '50', '100'].map((size) => ({
                name: `${size} per page`,
                value: size,
              }))}
            />
            {setSelectedRows
              ? `( ${selectedRows?.length ?? 0} / ${rows.length} ) total items`
              : `${rows.length} total items`}
          </div>
        )}
        <div className="data-table__footer-actions">
          {options?.clipboardCopy && (
            <CopyToClipboardButton
              data={JSON.stringify(data, null, 2)}
              label="Copy data to the Clipboard"
            />
          )}
        </div>
      </div>
      {options?.constraintText && (
        <div>
          <p>{options.constraintText}</p>
        </div>
      )}
    </div>
  );
};
