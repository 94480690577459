import React, { ReactElement, useContext, useState } from 'react';
import { AccountSelectionModal } from 'src/components/AccountSelectionModal';
import {
  TopNavigation,
  TopNavigationProps,
} from '@cloudscape-design/components';
import logo from '../../public/logo.png';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from 'src/context/accountContext';

const SiteHeader = (): ReactElement => {
  const [accountModalOpen, setAccountModalOpen] = useState(false);
  const navigate = useNavigate();
  const { selectedAccount, signout } = useContext(AccountContext);

  const clickHandler = (event: CustomEvent<{}>, path: string) => {
    event.preventDefault();
    navigate(path);
  };

  const utilityNavItems: TopNavigationProps.Utility[] = [];
  const accountRequiredItems: TopNavigationProps.Utility[] = [
    {
      type: 'button',
      iconName: 'settings',
      text: 'Account Settings',
      ariaLabel: 'Account Settings',
      title: 'Settings',
      onFollow: (e) => {
        clickHandler(e, `/accounts/${selectedAccount?.id}/accountSettings`);
      },
    },
  ];

  const generalItems: TopNavigationProps.Utility[] = [
    {
      type: 'button',
      text: 'Generate a Userscript',
      onFollow: (e) => clickHandler(e, '/userscript'),
    },
    {
      type: 'button',
      text: 'CLI Token',
      onFollow: (e) => clickHandler(e, '/cliOnboardingToken'),
    },
  ];

  utilityNavItems.push(...generalItems);
  if (selectedAccount) {
    utilityNavItems.push(...accountRequiredItems);
  }

  return (
    <div id="h" style={{ position: 'sticky', top: 0, zIndex: 1002 }}>
      <TopNavigation
        i18nStrings={{
          overflowMenuBackIconAriaLabel: 'Back',
          overflowMenuDismissIconAriaLabel: 'Dismiss',
          overflowMenuTitleText: 'All',
          overflowMenuTriggerText: 'Menu',
        }}
        identity={{
          href: '/',
          logo: {
            src: logo,
            alt: 'MLPigeon',
          },
          onFollow: (e) => clickHandler(e, '/'),
        }}
        utilities={[
          ...utilityNavItems,
          {
            type: 'menu-dropdown',
            text: `${selectedAccount?.name ?? 'Select an Account'}`,
            description: selectedAccount
              ? `${selectedAccount?.id} - ${selectedAccount?.type}`
              : '',
            iconName: 'user-profile',
            onItemFollow: (e) => {
              if (!e.detail.external) {
                e.preventDefault();

                if (e.detail.id === 'switch-account') {
                  setAccountModalOpen(true);
                } else if (e.detail.id === 'signout') {
                  signout();
                } else {
                  e.detail.href && navigate(e.detail.href);
                }
              }
            },
            items: [
              {
                id: 'switch-account',
                text: selectedAccount ? 'Switch Account' : 'Select Account',
                href: '#nogo',
              },
              {
                id: 'new-account',
                text: 'Onboard a new Account',
                href: '/accountSetup',
              },
              {
                id: 'support-group',
                text: 'Support',
                items: [
                  {
                    id: 'faqs',
                    text: 'MLPigeon FAQs',
                    href: 'https://w.amazon.com/bin/view/MLPigeon/FAQ',
                    external: true,
                    externalIconAriaLabel: ' (opens in new tab)',
                  },
                  {
                    id: 'slack',
                    text: 'Slack Channel',
                    href: 'https://amzn-wwc.slack.com/archives/C01BPFC6JE6',
                    external: true,
                    externalIconAriaLabel: ' (opens in new tab)',
                  },
                  {
                    id: 'office-hours',
                    text: 'Office Hours',
                    href: 'https://office-hour-scheduler.tools.aws.dev/schedule/2b6ed158-520a-4f2b-9e88-4fd21b614ae4',
                    external: true,
                    externalIconAriaLabel: ' (opens in new tab)',
                  },
                  {
                    id: 'sim',
                    text: 'SIM Ticketing',
                    href: 'https://t.corp.amazon.com/create/templates/e2e102bc-6cc2-4487-b5c3-d77d78d91827',
                    external: true,
                    externalIconAriaLabel: ' (opens in new tab)',
                  },
                ],
              },
              { id: 'signout', text: 'Sign out', href: '#nogo' },
            ],
          },
        ]}
      />
      <AccountSelectionModal
        visible={accountModalOpen}
        onDismiss={() => {
          setAccountModalOpen(false);
        }}
      />
    </div>
  );
};
export default SiteHeader;
