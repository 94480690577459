import React, { useContext, useState } from 'react';
import { urlList } from 'src/context/url';
import { UserContext } from 'src/context/userContext';
import { ApiContext } from 'src/context/apiContext';
import { ADD_TOAST } from 'src/context/reducer';
import { SageMakerLink } from 'src/components/SageMakerLink';
import { parse, build, ARN } from '@aws-sdk/util-arn-parser';

export interface PromoteConfig {
  modelName: string;
  arn: ARN;
}

export const useManualDeployment = () => {
  const [deploymentRequestLoading, setDeploymentRequestLoading] =
    useState<boolean>(false);
  const [deploymentRequestSuccess, setDeploymentRequestSuccess] = useState<
    boolean | null
  >(null);
  const [deploymentRequestError, setDeploymentRequestError] =
    useState<Error | null>(null);
  const [deploymentRequestFieldError, setDeploymentRequestFieldError] =
    useState<string | null>(null);
  const user = useContext(UserContext);
  const { dispatch } = useContext(ApiContext);

  const requestDeployment = async (
    sageMakerModelArn: string | ARN,
  ): Promise<void> => {
    let modelArn: ARN;

    if (typeof sageMakerModelArn === 'string') {
      try {
        modelArn = parse(sageMakerModelArn);
      } catch {
        setDeploymentRequestFieldError('Please enter a well formed arn.');
        return;
      }
    } else {
      modelArn = sageMakerModelArn;
    }

    if (modelArn.resource.includes('endpoint')) {
      setDeploymentRequestFieldError(
        'It looks like you have entered an endpoint arn, please supply the model arn instead.',
      );
    } else if (!modelArn.resource.startsWith('model/beta-')) {
      setDeploymentRequestFieldError(
        'It looks like you have entered a model arn that is not in beta. Please enter the arn for a model with a name that begins with beta-.',
      );
    } else {
      setDeploymentRequestFieldError(null);
      setDeploymentRequestLoading(true);
      await fetch(urlList.eventCommand, {
        method: 'POST',
        headers: {
          Authorization: user.token,
        },
        body: JSON.stringify({
          eventType: 'DeployPromotion',
          eventParameters: {
            sageMakerModelArn: build(modelArn),
          },
        }),
      })
        .then((res) => {
          if (!res.ok) {
            setDeploymentRequestSuccess(false);
            throw new Error(res.statusText);
          }

          setDeploymentRequestSuccess(true);
          const newModelArnResource = modelArn.resource.replace('beta-', '');
          const newModelArn = { ...modelArn, resource: newModelArnResource };

          dispatch({
            type: ADD_TOAST,
            payload: {
              header: 'Request Submitted Successfully',
              variant: 'success',
              description: (
                <div>
                  <p>
                    In a few moments you should be able to view the new model in
                    SageMaker.
                  </p>
                  <div>
                    <SageMakerLink arn={newModelArn} />
                  </div>
                </div>
              ),
            },
          });
          setDeploymentRequestLoading(false);
        })
        .catch((error) => {
          setDeploymentRequestError(error);
          setDeploymentRequestSuccess(false);
          setDeploymentRequestLoading(false);
          dispatch({
            type: ADD_TOAST,
            payload: {
              header: 'Error Sending Request',
              variant: 'danger',
              description: (
                <div>
                  <p>There was an issue creating your request:</p>
                  <div>{error.message}</div>
                </div>
              ),
            },
          });
        });
    }
  };

  return {
    requestDeployment,
    deploymentRequestLoading,
    deploymentRequestSuccess,
    deploymentRequestError,
    deploymentRequestFieldError,
  };
};
