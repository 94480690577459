import { lazy } from 'react';

export const LazyTopicSubscriptionPage = lazy(() =>
  import('src/views/TopicSubscriptionPage').then((module) => ({
    default: module.TopicSubscriptionPage,
  })),
);

export const LazyNewTopicSubscriptionPage = lazy(() =>
  import('src/views/NewTopicSubscriptionPage').then((module) => ({
    default: module.NewTopicSubscriptionPage,
  })),
);

export const LazyOnboardingPage = lazy(() =>
  import('src/views/OnboardingPage').then((module) => ({
    default: module.OnboardingPage,
  })),
);

export const LazyManualDeploymentPage = lazy(() =>
  import('src/views/ManualDeploymentPage').then((module) => ({
    default: module.ManualDeploymentPage,
  })),
);

export const LazyReserveRegistryNamePage = lazy(() =>
  import('src/views/ReserveRegistryNamePage').then((module) => ({
    default: module.ReserveRegistryNamePage,
  })),
);

export const LazyUserScriptGenerator = lazy(() =>
  import('src/views/UserScriptGenerator').then((module) => ({
    default: module.UserScriptGenerator,
  })),
);

export const LazyCLIOnboardingPage = lazy(() =>
  import('src/views/CLIOnboardingPage').then((module) => ({
    default: module.CLIOnboardingPage,
  })),
);

export const LazyAccountSettingsPage = lazy(() =>
  import('src/views/AccountSettingsPage').then((module) => ({
    default: module.AccountSettingsPage,
  })),
);

export const LazyOperatorHistoryPage = lazy(() =>
  import('src/views/OperatorHistoryPage').then((module) => ({
    default: module.OperatorHistoryPage,
  })),
);

export const LazyDashboardMetricsPage = lazy(() =>
  import('src/components/DashboardMetrics').then((module) => ({
    default: module.DashboardMetrics,
  })),
);

export const LazyModelRegistryHomePage = lazy(() =>
  import('src/views/ModelRegistry/ModelRegistryHomePage').then((module) => ({
    default: module.ModelRegistryHomePage,
  })),
);

export const LazyModelRegistryModelPage = lazy(() =>
  import('src/views/ModelRegistry/ModelPage').then((module) => ({
    default: module.ModelPage,
  })),
);

export const LazyModelVariationPage = lazy(() =>
  import('src/views/ModelRegistry/ModelVariationPage').then((module) => ({
    default: module.ModelVariationPage,
  })),
);

export const LazyEditModelDescriptionPage = lazy(() =>
  import('src/views/ModelRegistry/EditModelDescriptionPage').then((module) => ({
    default: module.EditModelDescriptionPage,
  })),
);

export const LazyEditReleaseNotesPage = lazy(() =>
  import('src/views/ModelRegistry/EditReleaseNotesPage').then((module) => ({
    default: module.EditReleaseNotesPage,
  })),
);

export const LazyLogViewPage = lazy(() =>
  import('src/views/LogViewPage').then((module) => ({
    default: module.LogViewPage,
  })),
);

export const LazyUnauthorizedPage = lazy(() =>
  import('src/views/UnauthorizedPage').then((module) => ({
    default: module.UnauthorizedPage,
  })),
);
