import React, { useContext } from 'react';
import { ApiContext } from 'src/context/apiContext';
import { useParams } from 'react-router-dom';
import { OperatorFlowDetails } from 'src/components/OperatorFlowDetails';
import { ContentLayout } from 'src/components/ContentLayout';
import { BreadcrumbNavigation } from 'src/components/BreadcrumbNavigation';
import { OperatorFlowVisualizationContainer } from 'src/components/OperatorFlowVisualizationContainer';
import { useQuery } from '@tanstack/react-query';
import { apiGetRequest } from 'src/utils/mlPigeonAPIRequest';

export function OperatorFlowPage() {
  const { state } = useContext(ApiContext);

  const { applicationId, modelVersionId, modelArtifactId, operatorFlowId } =
    useParams<Record<string, string>>();

  const operatorFlowDataQuery = useQuery<OperatorFlowJob, Error>({
    queryKey: ['operator-flow-data', operatorFlowId],
    queryFn: () =>
      apiGetRequest({
        endpoint: `operatorFlowJobs/${operatorFlowId}`,
      }),
  });

  const applicationDetails = state.applications.data.find(
    (app) => app['id'] === applicationId,
  );

  const modelVersionDetails = state.modelVersions.data.find(
    (mv) => mv['id'] === modelVersionId,
  );

  const modelArtifactDetails = state.modelArtifacts.data.find(
    (ma) => ma['id'] === modelArtifactId,
  );

  return (
    <>
      <ContentLayout
        heading={
          <>
            <BreadcrumbNavigation
              links={[
                {
                  display: `Account (${state.search.curAccountNo})`,
                  href: `/accounts/${state.search.curAccountNo}`,
                },
                {
                  display: applicationDetails?.name,
                  href: `/accounts/${state.search.curAccountNo}/applications/${state.search.curApplicationId}`,
                },
                {
                  display: modelVersionDetails?.name,
                  href: `/accounts/${state.search.curAccountNo}/applications/${state.search.curApplicationId}/modelVersions/${modelVersionDetails?.id}`,
                },
                {
                  display: 'Operator Flows',
                  href: `/accounts/${state.search.curAccountNo}/applications/${state.search.curApplicationId}/modelVersions/${modelVersionDetails?.id}/modelArtifacts/${modelArtifactDetails?.id}/operatorFlowJobs`,
                },
                { display: `Operator Flow (${operatorFlowId})` },
              ]}
            />

            <div className="operator-flow-details__header">
              <div className="operator-flow-details__heading-container">
                <h1>Operator Flow</h1>
                {operatorFlowDataQuery.data?.rolledBackOperatorFlowJobId && (
                  <div className="badge">Redeploy</div>
                )}
              </div>
              <div className="operator-flow-details__sub-heading">
                {operatorFlowId}
              </div>
            </div>
          </>
        }
      >
        <div className="operator-flow-visualization">
          <OperatorFlowDetails
            applicationData={{
              applicationName: applicationDetails?.name,
              modelVersionName: modelVersionDetails?.name,
              modelArtifactName: modelArtifactDetails?.modelArtifactName,
            }}
            operatorFlowData={operatorFlowDataQuery.data}
          />
          <div>
            <OperatorFlowVisualizationContainer
              operatorFlowDetails={operatorFlowDataQuery.data}
              operatorFlowDataLoading={operatorFlowDataQuery.isLoading}
            />
            {operatorFlowDataQuery.error && (
              <p className="error-text">
                {operatorFlowDataQuery.error.message}
              </p>
            )}
          </div>
        </div>
      </ContentLayout>
    </>
  );
}
